import { Banner } from '@hypercharge/bbs-website-commons/lib/types/bbs';
import { message } from 'antd';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FetchValue, hyperfetch } from '../utils/httpClient';
import { Status } from '../utils/types';

type ResultValue = {
  banners: Banner[];
  status: Status;
};

const fetchBanners = async (languageCode: string): Promise<Banner[]> => {
  const response: FetchValue<Banner[]> = await hyperfetch<Banner[]>(
    `/api/get-banners/${languageCode}`,
    {
      method: 'GET'
    }
  ).promise;

  if (response.error) {
    throw response.error;
  }

  if (response.data) {
    return response.data;
  } else {
    return [];
  }
};

export const useActiveBanners = ({ languageCode }): ResultValue => {
  const [banners, setBanners] = useState<Banner[]>([]);
  const [status, setStatus] = useState(Status.Idle);
  const { t } = useTranslation();

  useEffect(() => {
    setStatus(Status.Loading);
    fetchBanners(languageCode)
      .then(results => {
        setStatus(Status.Success);
        setBanners(results);
      })
      .catch(() => {
        setStatus(Status.Error);
        message.error(t('MESSAGE__ERROR'));
      });
  }, [t, languageCode]);

  return { banners, status };
};
