export enum Status {
  Idle,
  Loading,
  Error,
  Success
}

export enum RequestState {
  SENDING,
  SUCCESS,
  ERROR
}

export type ImageResizerT = {
  width?: number;
  height?: number;
};

export type FeaturedCategoryT = {
  id: string;
  title: string;
  url: string;
  showOnMainMenu: boolean;
  mainMenuDisplayOrder?: number;
};

export type Contact = {
  address1: string;
  address2: string;
  phoneNumber: string;
  email: string;
  latitude: string;
  longitude: string;
};

export type PageDescriptionT = {
  onPageDescription?: (description: string) => void;
};
