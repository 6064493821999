import { KeyValueT } from '@hypercharge/bbs-website-commons/lib/types/bbs';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

export type AttributesProps = {
  className?: string;
  attributes: KeyValueT[];
};

const ScWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  font-size: 1em;
  overflow: hidden;
  @media screen and (max-width: 575px) {
    max-height: unset!important;
  }
`;

const ScAttribute = styled.div`
  display: flex;
  flex-direction: row;
  min-width: 50%;
  @media print {
    line-height: 2em;
  }
`;

const ScKey = styled.div`
  min-width: 150px;
`;

const ScValue = styled.div`
  font-weight: bold;
  min-width: 100px;
  margin-left: 10px;
`;

const Attributes = ({ attributes, className }: AttributesProps) => {
  const { t } = useTranslation();

  return (
    <ScWrapper className={className} style={{maxHeight: `${attributes.length + 1}em`}}>
      {attributes.map((attribute, i) => {
        let value = attribute.value;
        try {
          if (attribute.meta && attribute.meta.type === 'number') {
            const valueAsNumber = parseFloat(value);
            if (!Number.isNaN(valueAsNumber)) {
              value = valueAsNumber.toLocaleString(undefined, {
                useGrouping: valueAsNumber > 9999
              });
              value = `${value} ${attribute.meta.unit}`;
            } else {
              value = '---';
            }
          }

          if (attribute.meta && attribute.meta.type === 'checkbox') {
            value = value && value.toString() === 'true' ? t('BOOLEAN_YES') : t('BOOLEAN_NO');
          }
        } finally {
        }
        return (
          <ScAttribute key={i}>
            <ScKey>{attribute.key}</ScKey>
            <ScValue>{value}</ScValue>
          </ScAttribute>
        );
      })}
    </ScWrapper>
  );
};

export { Attributes };
