import { Feature as FeatureT, FeatureType } from '@hypercharge/bbs-website-commons/lib/types/bbs';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import one_per_one from '../../assets/images/one_per_one.png';
import icon_age from '../../assets/images/vehicle_features/age.svg';
import icon_hp from '../../assets/images/vehicle_features/hp.svg';
import icon_km from '../../assets/images/vehicle_features/km.svg';
import icon_kw from '../../assets/images/vehicle_features/kw.svg';
import icon_euronorm from '../../assets/images/vehicle_features/leaf.svg';
import icon_retarder from '../../assets/images/vehicle_features/ret.svg';
import icon_transmission from '../../assets/images/vehicle_features/transmission.svg';

export type FeatureProps = {
  className?: string;
} & FeatureT;

function getUrlForType(type: FeatureType | undefined) {
  switch (type) {
    case FeatureType.AGE:
      return icon_age;
    case FeatureType.EURONORM:
      return icon_euronorm;
    case FeatureType.HP:
      return icon_hp;
    case FeatureType.KM:
      return icon_km;
    case FeatureType.KW:
      return icon_kw;
    case FeatureType.RETARDER:
      return icon_retarder;
    case FeatureType.TRANSMISSION:
      return icon_transmission;
    default:
      return one_per_one;
  }
}

const ScWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  img {
    height: 30px;
    min-width: 80px;
    margin-bottom: 3px;
    @media print {
      height: 15px;
    }
  }

  span {
    font-size: 0.8rem;
  }

  @media print {
    min-width: auto;
    span {
      font-size: 10px;
    }
  }
`;

const Feature = ({ description, type, imgUrl, className }: FeatureProps) => {
  const { t } = useTranslation();
  return (
    <ScWrapper className={className}>
      <img src={imgUrl || getUrlForType(type)} alt={t('VEHICLE__FEATURE')} />
      <span>{description}</span>
    </ScWrapper>
  );
};

export { Feature };
