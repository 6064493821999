import { FilterAggregation } from '@hypercharge/bbs-website-commons/lib/types/bbs';
import { Alert } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { MEDIA_QUERIES_MIN } from '../../utils/constants';
import { RadioImage } from './RadioImage';
import { SelectList } from './SelectList';
import { Slider } from './Slider';
import { WrapperSkeleton } from './Wrapper';

export type VehiclesFilterProps = {
  className?: string;
  filterAggregations: FilterAggregation[];
  didResetAggregations: boolean;
  onClear?: () => void;
  onChange?: (categoryId: string, values: string[]) => void;
};

const ScOuterWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0;
  font-size: 1em;

  ${MEDIA_QUERIES_MIN.md} {
    width: 250px;
  }
`;

const ScClearFilters = styled.span`
  align-self: flex-end;
  padding: 0 10px 0 10px;
  font-size: 0.8rem;
  line-height: 2rem;
  background-color: rgba(11, 119, 192, 0.05);
  width: 100%;
  margin-bottom: 1rem;
  text-align: end;

  button {
    background-color: rgba(11, 119, 192, 0.05);
    background-color: transparent;
    border: 0;
    padding: 0;
    cursor: pointer;
  }
`;

const ScAlert = styled(Alert)`
  && {
    margin: 0 auto 0.5rem auto;
  }
  max-width: 230px;
`;

const VehiclesFilter = ({
  className,
  onClear,
  onChange,
  filterAggregations,
  didResetAggregations = false
}: VehiclesFilterProps) => {
  const { t } = useTranslation();
  const hasAppliedFilters = filterAggregations.some(filter => {
    if (filter.multipleChoice) {
      return filter.multipleChoice.some(filter => {
        return filter.checked;
      });
    }

    if (filter.range) {
      if (
        filter.range.min === filter.range.value[0] &&
        filter.range.max === filter.range.value[1]
      ) {
        return false;
      }
      return true;
    }

    return false;
  });

  return (
    <ScOuterWrapper className={className}>
      {hasAppliedFilters ? (
        <ScClearFilters>
          <button onClick={onClear}>{t('VEHICLES_FILTER__CLEAR')}</button>
        </ScClearFilters>
      ) : (
        <ScClearFilters>&nbsp;</ScClearFilters>
      )}
      {didResetAggregations && (
        <ScAlert type="warning" message={t('VEHICLES_FILTER__RESET_FILTERS')} />
      )}
      {filterAggregations
        .sort(filterAggregation => {
          if (filterAggregation.imageChoice) return -1;
          return 0;
        })
        .map(filterAggregation => {
          if (filterAggregation.imageChoice) {
            return (
              <RadioImage
                key={filterAggregation.categoryId}
                category={filterAggregation.categoryDescription}
                categoryId={filterAggregation.categoryId}
                items={filterAggregation.imageChoice}
              />
            );
          }

          if (filterAggregation.multipleChoice) {
            return (
              <SelectList
                key={filterAggregation.categoryId}
                category={filterAggregation.categoryDescription}
                categoryId={filterAggregation.categoryId}
                items={filterAggregation.multipleChoice}
                onSelect={ids => onChange && onChange(filterAggregation.categoryId, ids)}
              />
            );
          }

          if (filterAggregation.range) {
            return (
              <Slider
                key={filterAggregation.categoryId}
                category={filterAggregation.categoryDescription}
                categoryId={filterAggregation.categoryId}
                min={filterAggregation.range.min}
                max={filterAggregation.range.max}
                value={filterAggregation.range.value}
                step={filterAggregation.range.step}
                onChange={values => {
                  onChange && onChange(filterAggregation.categoryId, values.map(v => v.toString()));
                }}
              />
            );
          }

          return <div key={filterAggregation.categoryId}></div>;
        })}
    </ScOuterWrapper>
  );
};

const VehiclesFilterSkeleton = () => {
  return (
    <ScOuterWrapper>
      <WrapperSkeleton />
      <WrapperSkeleton />
      <WrapperSkeleton />
      <WrapperSkeleton />
    </ScOuterWrapper>
  );
};

export { VehiclesFilter, VehiclesFilterSkeleton };
