import { Breadcrumb as AntdBreadcrumb } from 'antd';
import React, { PropsWithChildren } from 'react';
import styled from 'styled-components';
import { BBS_PRIMARY_COLOR, BREAKPOINTS } from '../utils/constants';
import LocalizedLink from './LocalizedLink';

const ScWrapper = styled.div`
  @media print {
    display: none;
  }

  .ant-breadcrumb {
    margin: 0 1rem 1rem 0rem;

    @media screen and (max-width: ${BREAKPOINTS.lg}px) {
      margin-top: 1rem;
    }

    a {
      color: ${BBS_PRIMARY_COLOR};
      font-weight: 300;
    }

    span:last-child a {
      color: #6b6b6b;
      font-weight: 500;
    }

    @media screen and (max-width: ${BREAKPOINTS.md}px) {
      margin-left: 10px;
      span {
        display: none;
      }

      span:nth-last-of-type(2),
      span:last-of-type {
        display: inline-block;
      }

      span:last-child .ant-breadcrumb-separator {
        display: none;
      }

      span:last-child a {
        color: ${BBS_PRIMARY_COLOR};
        font-weight: 300;
      }
    }
  }
`;

const Breadcrumb = (props: PropsWithChildren<any>) => {
  return (
    <ScWrapper>
      <div className="ant-breadcrumb">
        {props &&
          props.children &&
          props.children.map((item, i) => {
            return (
              <span key={i}>
                <LocalizedLink className="ant-breadcrumb-link" to={item.props.href}>
                  <span className="text">{item.props.children}</span>
                  <span className="ant-breadcrumb-separator">{props.separator}</span>
                </LocalizedLink>
              </span>
            );
          })}
      </div>
    </ScWrapper>
  );
};

Breadcrumb.Item = AntdBreadcrumb.Item;

export { Breadcrumb };
