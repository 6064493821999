import { Dropdown, Icon, Menu } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import Spinner from 'react-spinkit';
import styled from 'styled-components';
import { FeaturedCategoryT, Status } from '../utils/types';
import LocalizedLink from './LocalizedLink';

export type FeaturedCategoriesProps = {
  className?: string;
  categories: FeaturedCategoryT[];
  requestStatus: Status;
};

const ScCategorySelector = styled.div`
  display: flex;
  flex-direction: column;
  line-height: 1.2rem;

  @media print {
    display: none;
  }
`;

const ScLink = styled.a`
  font-weight: 700;
  color: white;
  :hover {
    color: white;
  }
`;

const ScSpinner = styled(Spinner)`
  margin: 50px;
`;

const CategorySelector = ({ categories, requestStatus }: FeaturedCategoriesProps) => {
  const { t } = useTranslation();

  const renderCategory = ({ title, id, url }: FeaturedCategoryT) => (
    <Menu.Item key={id}>
      <LocalizedLink to={url}>{title}</LocalizedLink>
    </Menu.Item>
  );

  const renderCategories = (categories: FeaturedCategoryT[]) => [
    ...categories.map(category => renderCategory(category))
  ];

  const renderMenu = (
    <Menu>
      {requestStatus === Status.Success ? (
        renderCategories(categories)
      ) : (
        <div>
          <ScSpinner name="three-bounce" fadeIn="none" />
        </div>
      )}
    </Menu>
  );

  return (
    <ScCategorySelector>
      <span>{t('VIEW_ALL')}</span>
      <Dropdown overlay={renderMenu} trigger={['click']}>
        <ScLink className="ant-dropdown-link" href="#">
          {t('CATEGORIES')} <Icon type="down" />
        </ScLink>
      </Dropdown>
    </ScCategorySelector>
  );
};

export { CategorySelector };
