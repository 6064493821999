import {
  FilterAggregation,
  FilterAggrMultipleChoice
} from '@hypercharge/bbs-website-commons/lib/types/bbs';
import { Button, Checkbox } from 'antd';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { partition } from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { BBS_PRIMARY_COLOR } from '../../utils/constants';
import { Wrapper, WrapperProps } from './Wrapper';

export type SelectListProps = {
  items: FilterAggregation['multipleChoice'];
  onSelect?: (id: string[]) => void;
} & WrapperProps;

const ScWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const ScItem = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  .ant-checkbox-wrapper {
    color: ${BBS_PRIMARY_COLOR};
  }
`;

const ScViewLessMoreContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 10px;

  .ant-checkbox-wrapper {
    color: ${BBS_PRIMARY_COLOR};
  }
`;

const ScCount = styled.span`
  color: #a8a8a8;
  font-size: 1em;
`;

const ScCheckbox = styled(Checkbox)`
  &&& {
    font-size: 1em;
  }
`;

const ScBtnViewAll = styled(Button)`
  justify-self: flex-end;
  border-color: ${BBS_PRIMARY_COLOR};
  color: ${BBS_PRIMARY_COLOR};
  font-size: 1em;
`;

const SelectList = ({
  items = [],
  onSelect,
  category,
  categoryId,
  startOpen = true,
  onCollapse
}: SelectListProps) => {
  const { t } = useTranslation();
  const [isShowingAll, setIsShowingAll] = useState<boolean>(false);

  const filtersWithResult: FilterAggrMultipleChoice[] = useMemo(
    () => items.filter(item => item.count),
    [items]
  );

  // this is a hack to work around the fact that the way filtering is implemented
  // the decision of what is selected is coming from the backend
  // and not using the url to know what filters are selected
  const [selectedOptionsIds, setSelectedOptionsIds] = useState<string[]>(
    items.filter(item => item.checked).map(item => item.id)
  );

  const [selectedOptions, otherOptions] = useMemo(
    () =>
      partition(isShowingAll ? items : filtersWithResult, item =>
        selectedOptionsIds.includes(item.id)
      ),
    [filtersWithResult, isShowingAll, items, selectedOptionsIds]
  );

  const visibleOptions = useMemo(
    () => (isShowingAll ? otherOptions : otherOptions.slice(0, filtersWithResult.length)),
    [filtersWithResult.length, isShowingAll, otherOptions]
  );

  useEffect(() => {
    setSelectedOptionsIds(items.filter(item => item.checked).map(item => item.id));
  }, [items]);

  const onSelectOption = (e: CheckboxChangeEvent) => {
    setIsShowingAll(false);
    let optionsAfterUpdate: string[] = [];
    if (e.target.checked) {
      optionsAfterUpdate = [...selectedOptions.map(op => op.id), e.target.value];
    } else {
      optionsAfterUpdate = selectedOptions.filter(op => op.id !== e.target.value).map(op => op.id);
    }
    setSelectedOptionsIds(optionsAfterUpdate);
    onSelect && onSelect(optionsAfterUpdate);
  };

  return (
    <Wrapper
      category={category}
      categoryId={categoryId}
      onCollapse={onCollapse}
      startOpen={startOpen}
    >
      <ScWrapper>
        {selectedOptions.map(op => (
          <ScItem key={op.id}>
            <ScCheckbox onChange={onSelectOption} value={op.id} checked>
              {op.description}
            </ScCheckbox>
            <ScCount>{op.count}</ScCount>
          </ScItem>
        ))}
        {visibleOptions.map(op => (
          <ScItem key={op.id}>
            <ScCheckbox onChange={onSelectOption} value={op.id} checked={false}>
              {op.description}
            </ScCheckbox>
            <ScCount>{op.count}</ScCount>
          </ScItem>
        ))}
        <ScViewLessMoreContainer>
          {!isShowingAll && items.length === visibleOptions.length ? null : (
            <ScBtnViewAll
              size="small"
              type="default"
              onClick={() => setIsShowingAll(!isShowingAll)}
            >
              {isShowingAll ? t('VIEW_LESS') : t('VIEW_ALL')}
            </ScBtnViewAll>
          )}
        </ScViewLessMoreContainer>
      </ScWrapper>
    </Wrapper>
  );
};

export { SelectList };
