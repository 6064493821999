import { Banner } from '@hypercharge/bbs-website-commons/lib/types/bbs';
import { Button } from 'antd';
import { isEmpty } from 'lodash';
import React from 'react';
import Skeleton from 'react-loading-skeleton';
import { useMediaQuery } from 'react-responsive';
import styled from 'styled-components';
import { BBS_PRIMARY_COLOR, BREAKPOINTS } from '../utils/constants';
import { getImageUrl } from '../utils/files';
import LocalizedLink from './LocalizedLink';

export type StoryBannerProps = {
  banner: Banner;
  className?: string;
};

const DEFAULT_HEIGHT = 180;

const ScBannerWrapper = styled.div<{ isInverted: boolean; bannerHeight: number }>`
  display: flex;
  flex-direction: ${props => (props.isInverted ? 'row-reverse' : 'row')};
  box-shadow: 0px 0px 6px 2px rgba(0, 0, 0, 0.1);
  background-color: white;
  > img {
    height: 100%;
    min-width: 200px;
  }

  @media screen and (max-width: ${BREAKPOINTS.lg}px) {
    height: auto;
  }
`;

const ScStoryWrapper = styled.div<{ isInverted: boolean; isMobile: boolean }>`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  padding: 1rem;
  overflow: hidden;
  align-items: ${props => (props.isInverted ? 'flex-end' : 'initial')};

  h1 {
    color: ${BBS_PRIMARY_COLOR};
    font-size: 1rem;
    font-weight: 400;
    margin-bottom: 6px;
    word-wrap: break-word;
  }

  article {
    width: 100%;
    line-height: 1.1rem;
    text-align: justify;
    margin-bottom: 12px;
    word-wrap: break-word;
  }
`;

const ScButton = styled(Button)`
  cursor: pointer;
`;

const ScSkeletonWrapper = styled.div`
  margin-bottom: 10px;
`;

const ScImageAsBackground = styled.div<{ imageUrl: string }>`
  min-width: 200px;
  background-image: url(${props => props.imageUrl});
  background-size: cover;
`;

const StoryBanner = ({ banner, className }: StoryBannerProps) => {
  const { title, description, image, callToAction, isInverted, height } = banner;
  const bannerHeight = height || DEFAULT_HEIGHT;
  const imageUrl = isEmpty(image) ? '' : getImageUrl(image![0], { height: bannerHeight });
  const isMobile = useMediaQuery({ maxWidth: BREAKPOINTS.sm });

  return (
    <ScBannerWrapper className={className} isInverted={!!isInverted} bannerHeight={bannerHeight}>
      {isMobile ? null : <ScImageAsBackground imageUrl={imageUrl} />}
      <ScStoryWrapper isInverted={!!isInverted} isMobile={isMobile}>
        <h1>{title}</h1>
        <article>{description}</article>
        {callToAction && (
          <div>
            <LocalizedLink to={callToAction.link}>
              <ScButton type="primary">{callToAction.text}</ScButton>
            </LocalizedLink>
          </div>
        )}
      </ScStoryWrapper>
    </ScBannerWrapper>
  );
};

const StoryBannerSkeleton = (): JSX.Element => {
  return (
    <ScSkeletonWrapper>
      <Skeleton height={DEFAULT_HEIGHT} />
    </ScSkeletonWrapper>
  );
};

export { StoryBanner, StoryBannerSkeleton };
