import { CarrouselImage } from '@hypercharge/bbs-website-commons/lib/types/bbs';
import { Button, Icon, notification, Skeleton } from 'antd';
import { isEmpty } from 'lodash';
import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';
import styled from 'styled-components';
import fallbackImage from '../assets/images/fallback.png';
import { LayoutFixedWidthWrapper } from '../components/LayoutFixedWidthWrapper';
import { Loading } from '../components/Loading';
import LocalizedLink from '../components/LocalizedLink';
import { RelatedVehicles } from '../components/RelatedVehicles';
import { Share } from '../components/Share';
import { getTagAttributesForStatus, Tag, TagAttributesForStatus } from '../components/Tag';
import { Attributes } from '../components/vehicle/Attributes';
import { Carrousel } from '../components/vehicle/Carrousel';
import { DescriptionBar } from '../components/vehicle/DescriptionBar';
import { Equipment } from '../components/vehicle/Equipment';
import { Feature } from '../components/vehicle/Feature';
import { Tires } from '../components/vehicle/Tires';
import { useRelatedVehicles } from '../context/RelatedVehiclesHook';
import { generateVehicleUrl, useVehicle } from '../context/VehicleHook';
import NotFound from '../layouts/NotFound';
import { BBS_PRIMARY_COLOR, BREAKPOINTS, MEDIA_QUERIES_MIN } from '../utils/constants';
import { PageDescriptionT } from '../utils/types';
import { VehiclePrint } from './VehiclePrint';
import { MetaTags } from '../components/MetaTags';

const BreadcrumbsWrapper = styled.div`
  ${MEDIA_QUERIES_MIN.md} {
    margin: 0 10px;
  }
`;

const ScWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  align-items: center;
  padding-bottom: 10px;
  font-size: 1em;
  position: relative;

  @media print {
    display: none;
  }
`;

const ScContactAndFeatures = styled.div`
  background-color: white;
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  font-size: 1rem;
  margin-bottom: 1rem;
  @media screen {
    box-shadow: 2px 2px 4px 2px #e8e8e8;
  }
  @media print {
    margin-bottom: 0;
    font-size: 0.7rem;
  }
`;

const ScContact = styled.div`
  height: 150px;
  min-width: 250px;
  width: 250px;
  border-right: 1px solid #ededed;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  align-items: center;
  margin-right: 1rem;
  font-size: 0.8rem;

  @media screen and (max-width: ${BREAKPOINTS.sm}px) {
    display: none;
  }

  @media print {
    display: none;
  }
`;
const ScFeatures = styled.div`
  display: flex;
  flex-direction: row;
  flex-flow: wrap;
  align-items: center;
  justify-content: start;
  padding: 1rem;
  margin: 0 auto;

  @media screen and (orientation: portrait) {
    justify-content: center;
  }
  @media print {
    padding: 0;
    flex-flow: nowrap;
    display: none;
  }
`;

const ScVehicleFeature = styled(Feature)`
  margin: 0 15px 10px 15px;
  @media print {
    margin: 0;
  }
`;

const ScDetails = styled.div`
  display: flex;
  flex-direction: column;
  background-color: white;
  font-size: 0.8rem;
  line-height: 1.5rem;

  @media screen {
    box-shadow: 2px 2px 4px 2px #e8e8e8;
    padding: 0rem 1rem 1rem 1rem;
  }

  @media print {
    h1 {
      color: black;
      margin: 0.5rem;
    }
    line-height: 14px;
    padding: 0;
  }
`;

const ScRowWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

const ScSpacer = styled.div`
  flex-grow: 1;
`;

const ScVehicleTires = styled(Tires)`
  min-width: 280px;
`;

const ScShareAndPrint = styled.div`
  display: flex;
  flex-direction: row;
  padding: 10px 0;
  align-items: flex-end;
  flex-wrap: wrap;

  @media screen and (max-width: 1000px) {
    padding: 10px 1rem;
  }

  > * {
    margin-top: 10px;
  }

  @media print {
    display: none;
    padding: 0;
  }
`;

const ScPrintButton = styled(Button)`
  min-width: 100px;
  text-transform: uppercase;
  margin-right: 1em;
  @media screen and (max-width: ${BREAKPOINTS.sm}px) {
    margin-bottom: 10px;
  }
`;

const ScSimilarVehicles = styled.div`
  @media screen and (max-width: 1000px) {
    padding: 10px 1rem;
  }

  @media print {
    display: none;
    padding: 0;
  }
`;

const ScSectionTitle = styled.div`
  border-bottom: 1px solid #eeeeee;
  color: #6f6f6f;
  font-size: 1.5em;
  font-weight: 300;
  margin: 0.5em 0 0.5em 0;

  @media screen and (max-width: ${BREAKPOINTS.sm}px) {
    text-align: center;
  }
  @media print {
    font-size: 12px;
  }
`;

const ScLayoutFixedWidthWrapper = styled(LayoutFixedWidthWrapper)`
  position: relative;
  z-index: 10;
`;

const ScTag = styled(Tag)`
  &&& {
    position: absolute;
    top: 0;
    left: -2px;
    font-size: 4rem;
    height: auto;
    line-height: initial;
    @media print {
      color: #000;
    }
  }
`;
const ScDescriptionAndSubtitle = styled.div`
  @media print {
    display: none;
  }
  background-color: ${BBS_PRIMARY_COLOR};
  text-align: center;
  width: 100%;
  padding: 5px;
  line-height: 1.5rem;
`;

const ScDescriptionForPrint = styled.div`
  @media screen {
    display: none;
  }

  text-align: center;
  h1 {
    margin-bottom: 0;
  }
`;

const ScDescription = styled.h1`
  font-size: 1rem;
  color: white;
  font-weight: 600;
  margin: 0;
  @media print {
    color: black;
  }
`;

const ScSubtitle = styled.p`
  color: white;
  margin: 0;
`;

const ScContactButtonMobile = styled.div`
  margin-bottom: 1rem;
  width: 90%;
  button {
    width: 100%;
  }

  @media print {
    display: none;
  }
`;

const ScImageForPrintAndDescription = styled.div`
  width: 100%;
  > img {
    display: none;
  }

  @media print {
    margin-top: 20px;
    margin-bottom: 20px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    position: relative;
    > img {
      max-width: 40%;
      display: inline;
    }
  }
`;

const ScSeatsWrapper = styled.div`
  @media print {
    display: none;
  }
`;

const ScVehiclePrint = styled(VehiclePrint)`
  display: none;
  @media print {
    display: block;
  }
`;

function fallbackForCarrousel(): CarrouselImage[] {
  return [
    {
      carrouselUrl: fallbackImage,
      fullImageUrl: fallbackImage
    }
  ];
}

const Vehicle = ({
  id: vehicleId,
  onPageDescription,
  breadcrumbs
}: {
  id: string;
  breadcrumbs: React.ReactNode;
} & PageDescriptionT) => {
  const { t, i18n } = useTranslation();
  const isMedium = useMediaQuery({ minWidth: BREAKPOINTS.md });

  const { vehicle, notFound, otherFailure } = useVehicle(vehicleId, i18n.language);
  const { summaries: relatedVehiclesSummaries, failedToFetchRelatedVehicles } = useRelatedVehicles(
    vehicleId,
    i18n.language
  );

  const tagAttributes = useMemo<TagAttributesForStatus>(() => {
    if (vehicle) {
      return getTagAttributesForStatus(t, vehicle.summary.status);
    } else {
      return {
        text: '',
        color: '#FFFFFF00'
      };
    }
  }, [t, vehicle]);

  useEffect(() => {
    if (otherFailure) {
      notification.error({ message: t('ERROR_BOUNDARY__TITLE'), duration: 0 });
    }
  }, [otherFailure, t]);

  useEffect(() => {
    if (vehicle && onPageDescription) {
      onPageDescription(vehicle.summary.description);
    }
  }, [onPageDescription, vehicle]);

  if (notFound) {
    return <NotFound />;
  }

  if (!vehicle) {
    return <Loading />;
  }

  const contactUrl = `/contact?vehicleName=${vehicle.summary.description}&vehicleReference=${vehicle.summary.referenceNumber}&id=${vehicle.summary.id}`;
  const defaultVehicleImg = vehicle.carrouselImages && vehicle.carrouselImages.length ? vehicle.carrouselImages[0].fullImageUrl : '';

  return (
    <>
      <BreadcrumbsWrapper>{breadcrumbs}</BreadcrumbsWrapper>
      <ScWrapper itemScope itemType="http://schema.org/Product">
        <MetaTags
          title={`${t('VEHICLE')} - ${vehicle.summary.description}`}
          description={vehicle.summary.subtitle}
          ogImage={defaultVehicleImg}
        />
        <ScDescriptionForPrint>
          <h1 itemProp="name">{vehicle.summary.description}</h1>
          <h5 itemProp="description">{vehicle.summary.subtitle}</h5>
          <meta
            itemProp="url"
            content={generateVehicleUrl(vehicle.summary.id, vehicle.summary.slug)}
          />
        </ScDescriptionForPrint>
        {!isMedium && (
          <ScDescriptionAndSubtitle>
            <ScDescription>{vehicle.summary.description}</ScDescription>
            <ScSubtitle>{vehicle.summary.subtitle}</ScSubtitle>
          </ScDescriptionAndSubtitle>
        )}
        {vehicle.carrouselImages && vehicle.carrouselImages.length > 0 && (
          <meta itemProp="image" content={vehicle.carrouselImages[0].fullImageUrl} />
        )}
        {!isEmpty(tagAttributes.text) && (
          <ScLayoutFixedWidthWrapper>
            <ScTag color={tagAttributes.color}>{tagAttributes.text}</ScTag>
          </ScLayoutFixedWidthWrapper>
        )}
        <Carrousel
          images={vehicle.carrouselImages ? vehicle.carrouselImages : fallbackForCarrousel()}
          alt={vehicle.summary.description}
        />
        <ScImageForPrintAndDescription>
          {vehicle.carrouselImages && vehicle.carrouselImages.length > 0 && (
            <img src={vehicle.carrouselImages[0].carrouselUrl} alt={vehicle.summary.description} />
          )}
          <DescriptionBar
            description={vehicle.summary.description}
            subtitle={vehicle.summary.subtitle}
            price={vehicle.summary.price}
            priceComment={t('VEHICLE__VAT_EXCLUDED')}
            referenceNumber={vehicle.summary.referenceNumber || ''}
            showDescription={isMedium}
            status={vehicle.summary.status}
          />
        </ScImageForPrintAndDescription>
        <LayoutFixedWidthWrapper>
          <ScContactAndFeatures>
            <ScContact>
              <p>{t('VEHICLE__CONTACT_TEXT')}</p>
              <LocalizedLink to={contactUrl}>
                <Button type="primary">{t('NAVIGATION__CONTACT')}</Button>
              </LocalizedLink>
            </ScContact>
            <ScFeatures>
              {vehicle.features.map((feature, i) => {
                let description = feature.description;
                if (feature.meta && feature.meta.type === 'number') {
                  try {
                    let value = Number.parseFloat(description);
                    if (!Number.isNaN(value)) {
                      description = value.toLocaleString(undefined, {
                        useGrouping: true
                      });
                      description = `${description} ${feature.meta.unit}`;
                    }
                  } finally {
                  }
                }
                return <ScVehicleFeature key={i} {...feature} description={description} />;
              })}
            </ScFeatures>
          </ScContactAndFeatures>
        </LayoutFixedWidthWrapper>
        {!isMedium && (
          <ScContactButtonMobile>
            <LocalizedLink to={contactUrl}>
              <Button type="primary">{t('VEHICLE__INTERESTED_TEXT')}</Button>
            </LocalizedLink>
          </ScContactButtonMobile>
        )}
        <LayoutFixedWidthWrapper>
          <ScDetails>
            {vehicle.technicalAttrs.length > 0 && (
              <>
                <ScSectionTitle className="no-top-margin">
                  {t('VEHICLE__TECHNICAL_DETAILS')}
                </ScSectionTitle>
                <Attributes attributes={vehicle.technicalAttrs} />
              </>
            )}
            {vehicle.tires.length > 0 && (
              <>
                <ScSectionTitle>{t('VEHICLE__TIRES')}</ScSectionTitle>
                <ScRowWrapper>
                  <ScVehicleTires axles={vehicle.tires} />
                  <ScSpacer />
                </ScRowWrapper>
              </>
            )}
            {vehicle.seats.length > 0 && (
              <ScSeatsWrapper>
                <ScSectionTitle>{t('SEATS')}</ScSectionTitle>
                <Attributes attributes={vehicle.seats} />
              </ScSeatsWrapper>
            )}
            {vehicle.equipments.length > 0 && (
              <>
                <ScSectionTitle>{t('VEHICLE__EQUIPMENT')}</ScSectionTitle>
                <Equipment equipments={vehicle.equipments} />
              </>
            )}
          </ScDetails>
          <ScShareAndPrint>
            <Share subject={vehicle.summary.description} />
            <ScSpacer />
            <div>
              <ScPrintButton type="default" onClick={window.print}>
                <Icon type="printer" />
                {t('PRINT')}
              </ScPrintButton>
              <LocalizedLink to={contactUrl}>
                <Button type="primary">{t('VEHICLE__INTERESTED_TEXT')}</Button>
              </LocalizedLink>
            </div>
          </ScShareAndPrint>
          {!failedToFetchRelatedVehicles && (
            <ScSimilarVehicles>
              <ScSectionTitle>{t('VEHICLES__RELATED_VEHICLES')}</ScSectionTitle>
              {relatedVehiclesSummaries ? (
                <RelatedVehicles vehicles={relatedVehiclesSummaries} />
              ) : (
                <Skeleton paragraph />
              )}
            </ScSimilarVehicles>
          )}
        </LayoutFixedWidthWrapper>
      </ScWrapper>
      <ScVehiclePrint vehicle={vehicle} />
    </>
  );
};

export { Vehicle };
