import { SortOrder } from '@hypercharge/lambda-utils/lib/search/searchTypes';
import { Dropdown, Menu } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { BBS_PRIMARY_COLOR } from '../../utils/constants';

export type SortingProps = {
  sortingOptions: {
    description: string;
    orderBy: string;
    orderByType: SortOrder;
  }[];
  selectedOrder: string;
  selectedOrderType: SortOrder;
  className?: string;
  onChange?: (orderBy: string, orderByType: SortOrder) => void;
};

const Sorting = ({
  sortingOptions,
  className,
  onChange,
  selectedOrder,
  selectedOrderType
}: SortingProps) => {
  const { t } = useTranslation();

  const menu = (
    <Menu>
      {sortingOptions.map(option => (
        <Menu.Item
          onClick={() => {
            onChange && onChange(option.orderBy, option.orderByType);
          }}
          key={`${option.orderBy}${option.orderByType}`}
        >
          {option.description}
        </Menu.Item>
      ))}
    </Menu>
  );

  const selectedOption = (() => {
    const option = sortingOptions.find(
      option => option.orderBy === selectedOrder && option.orderByType === selectedOrderType
    );
    if (option) return option.description;
    return '';
  })();

  const ScButtonLink = styled.button`
    background: none;
    border: 0;
    padding: 0;
    margin: 0;
    color: ${BBS_PRIMARY_COLOR};
    cursor: pointer;
  `;

  return (
    <div className={className}>
      {t('VEHICLES_RESULTS__SORT_BY')}
      <Dropdown overlay={menu}>
        <ScButtonLink>&nbsp;{selectedOption}</ScButtonLink>
      </Dropdown>
    </div>
  );
};

export { Sorting };
