import { isEmpty } from 'lodash';
import React, { useEffect } from 'react';
import Skeleton from 'react-loading-skeleton';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { BBS_PRIMARY_COLOR } from '../utils/constants';
import { FeaturedCategoryT } from '../utils/types';
import LocalizedLink from './LocalizedLink';

export type FeaturedCategoriesProps = {
  className?: string;
  categories: FeaturedCategoryT[];
  showMargin: boolean;
  onClick?: (id: string) => void;
};

const ScList = styled.ul<{ showMargin: boolean }>`
  display: flex;
  padding: 0;
  margin: ${props => (props.showMargin ? '0 0 14px 0' : '0')};

  li {
    list-style: none;
    font-weight: 500;
  }
`;

const ScInlineList = styled(ScList)`
  flex-direction: row;
  flex-wrap: wrap;
  background-color: rgba(11, 119, 192, 0.05);
  justify-content: space-around;
  height: 40px;
  align-items: center;
  li {
    color: ${BBS_PRIMARY_COLOR};
  }
`;

const SEPARATOR = '__SEPARATOR__';
const SKELETON = '__SKELETON__';
const SPECIAL_IDS = [SEPARATOR, SKELETON];

const ScVerticalList = styled(ScList)`
  flex-direction: column;
  background-color: rgba(11, 119, 192, 0.71);
  padding-inline-start: 0%;
  li {
    padding: 0.5rem 0.5rem;
    border-bottom: 1px solid rgba(255, 255, 255, 0.5);

    a {
      color: white;
    }
  }

  li:last-of-type {
    border-bottom: 0;
  }

  li.${SEPARATOR} {
    padding: 0;
  }
`;

const ScSeparator = styled.div`
  height: 1px;
  background-color: white;
`;

const ScSkeletonContainer = styled.div`
  height: 1em;
  width: 80px;
`;

const ScLinkWrapper = styled.div`
  width: 100%;
`;

const ListOfItems = ({ categories, onClick }: FeaturedCategoriesProps) => {
  const history = useHistory();

  return (
    <>
      {categories.map((category, i) => (
        <li
          key={i}
          className={category.id === SEPARATOR ? SEPARATOR : ''}
          onClick={() => onClick && onClick((category as FeaturedCategoryT).id || i.toString())}
        >
          {category.id === SEPARATOR && <ScSeparator />}
          {category.id === SKELETON && (
            <ScSkeletonContainer>
              <Skeleton />
            </ScSkeletonContainer>
          )}
          {!SPECIAL_IDS.includes(category.id) && (
            <ScLinkWrapper onClick={() => history.push((category as FeaturedCategoryT).url)}>
              <LocalizedLink to={(category as FeaturedCategoryT).url}>
                {(category as FeaturedCategoryT).title}
              </LocalizedLink>
            </ScLinkWrapper>
          )}
        </li>
      ))}
    </>
  );
};

const InlineList = ({ className, categories, showMargin, onClick }: FeaturedCategoriesProps) => {
  return (
    <ScInlineList showMargin={showMargin} className={className}>
      {isEmpty(categories) ? null : (
        <ListOfItems showMargin={showMargin} categories={categories} onClick={onClick} />
      )}
    </ScInlineList>
  );
};

export const HeaderOverlay = styled.div<{ top: number; isOpen: boolean }>`
  position: fixed;
  z-index: 2000;
  top: 0px;
  bottom: 0;
  width: 100vw;
  background-color: rgba(0, 0, 0, 0.3);
  padding-top: ${props => props.top}px;
  display: ${props => (props.isOpen ? 'block' : 'none')};
`;

const VerticalList = ({
  className,
  categories,
  showMargin,
  onClick,
  isOpen,
  top = 64,
  onClose
}: FeaturedCategoriesProps & { isOpen: boolean; top?: number; onClose: () => void }) => {
  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = '';
    }
    return () => {
      document.body.style.overflow = '';
    };
  }, [isOpen]);
  return (
    <HeaderOverlay top={top} onClick={onClose} isOpen={isOpen}>
      <ScVerticalList showMargin={showMargin} className={className}>
        {isEmpty(categories) ? null : (
          <ListOfItems showMargin={showMargin} categories={categories} onClick={onClick} />
        )}
      </ScVerticalList>
    </HeaderOverlay>
  );
};

const FeaturedCategories = {
  SEPARATOR,
  SKELETON,
  InlineList,
  VerticalList
};

export { FeaturedCategories };
