import { BusType, VehicleFilter } from '@hypercharge/bbs-website-commons/lib/types/bbs';
import { Divider, Icon, Layout } from 'antd';
import { isEmpty, sortBy } from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';
import { useHistory, useLocation } from 'react-router-dom';
import slugify from 'speakingurl';
import styled from 'styled-components';
import bbs_logo from '../assets/images/bbs_logo.gif';
import { CategorySelector } from '../components/CategorySelector';
import { FeaturedCategories } from '../components/FeaturedCategories';
import LanguageSelector from '../components/LanguageSelector';
import LocalizedLink from '../components/LocalizedLink';
import { Search } from '../components/Search';
import { useConfiguration } from '../context/ConfigurationProvider';
import { getLocalizedPath } from '../context/i18n';
import { convertSearchParamsIntoFilter } from '../context/SearchVehiclesHook';
import { BBS_PRIMARY_COLOR, BREAKPOINTS, CATEGORY_OTHERS } from '../utils/constants';
import { FeaturedCategoryT, Status } from '../utils/types';

const { Header: AntdHeader } = Layout;

const ScLogo = styled.img`
  max-height: 50px;
`;

const ScMarginRight = styled.div`
  margin-right: 1rem;
`;

const ScHeader = styled(AntdHeader)`
  background-color: ${BBS_PRIMARY_COLOR};
  display: flex;
  flex-direction: row;
  color: white;
  align-items: center;
  @media screen and (max-width: ${BREAKPOINTS.lg}px) {
    padding: 0 10px;
  }
  @media print {
    display: none;
  }
`;

const ScHeaderCompact = styled(ScHeader)`
  display: grid;
  grid-template-columns: 1fr min-content min-content;
  grid-column-gap: 5px;
  line-height: initial;
`;

const ScSearchHeader = styled(AntdHeader)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: transparent;
  line-height: 45px;
  height: 45px;

  @media screen and (max-width: ${BREAKPOINTS.lg}px) {
    padding: 0 10px;
  }
  @media print {
    display: none;
  }
`;

const ScInlineCategories = styled(FeaturedCategories.InlineList)`
  @media print {
    display: none;
  }
`;

const ScBlankSpace = styled.div`
  flex-grow: 1;
`;

const ScSearch = styled.div`
  width: 300px;
  @media print {
    display: none;
  }
`;

const ScIcon = styled(Icon)`
  font-size: 1.8rem;
`;

const ScMenuWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

const ScDivider = styled(Divider)`
  height: 2.1rem !important;
`;
const ScLanguageSelector = styled(LanguageSelector)`
  @media print {
    display: none;
  }
`;

const ScBranding = styled.div`
  display: flex;
  @media screen and (min-width: ${BREAKPOINTS.lg}px) {
    margin-right: 20px;
  }

  .company_name {
    display: flex;
    flex-direction: column;
    justify-content: center;

    .name {
      font-size: 1.5em;
      line-height: 1.5em;
      @media screen and (max-width: ${BREAKPOINTS.sm}px) {
        font-size: 1.2em;
        line-height: 1.4em;
      }
      @media screen and (max-width: ${BREAKPOINTS.xs}px) {
        font-size: 0.9em;
        line-height: 1.5em;
      }
      color: white;
      margin: 0;
      text-transform: uppercase;
      text-shadow: 1px 1px 0px #2d4f6a;
      font-weight: 800;
    }

    .subtitle {
      font-size: 1em;
      line-height: 1em;
      font-weight: 500;
      @media screen and (max-width: ${BREAKPOINTS.sm}px) {
        font-size: 0.8em;
        line-height: 0.8em;
      }
      @media screen and (max-width: ${BREAKPOINTS.xs}px) {
        font-size: 0.6em;
        line-height: 1em;
      }
      color: #afb4b9;
      margin: 0;
    }
  }
`;

const ScVerticalList = styled(FeaturedCategories.VerticalList)`
  background-color: #589ed3;
  @media print {
    display: none;
  }
`;

const ScLanguageSelectorCompact = styled(ScLanguageSelector)`
  font-size: 1.2em;
  height: 100%;
`;

const menuPlaceholder: FeaturedCategoryT = {
  id: FeaturedCategories.SKELETON,
  title: '',
  url: '',
  showOnMainMenu: true
};

const Header: React.FC = () => {
  const isLarge = useMediaQuery({ minWidth: BREAKPOINTS.lg });
  const { t, i18n } = useTranslation();
  const location = useLocation();
  const history = useHistory();
  const { busTypes, status, appStyle, companyInformation } = useConfiguration();
  const { showMarginAfterMenu } = appStyle;

  const featuredCategories: FeaturedCategoryT[] = useMemo(() => {
    const allVehicles = {
      id: '',
      title: t('NAVIGATION__ALL_BUSES'),
      url: '/vehicles',
      showOnMainMenu: true,
      mainMenuDisplayOrder: 0
    };

    if (status !== Status.Success) {
      return [
        allVehicles,
        menuPlaceholder,
        menuPlaceholder,
        menuPlaceholder,
        menuPlaceholder,
        menuPlaceholder,
        menuPlaceholder
      ];
    }

    const findTypeOther = (type: BusType) => type.entityId === CATEGORY_OTHERS;
    const convertTypeToFeaturedCategory = (type: BusType): FeaturedCategoryT => ({
      id: type.entityId,
      title: type.title,
      url: `/category/${type.entityId}/${slugify(type.title)}`,
      showOnMainMenu: type.showOnMainMenu,
      mainMenuDisplayOrder: type.mainMenuDisplayOrder
    });

    const categories: FeaturedCategoryT[] = busTypes
      .filter(type => !findTypeOther(type))
      .map(convertTypeToFeaturedCategory)
      .filter(featuredCategory => !isEmpty(featuredCategory.title));

    categories.splice(0, 0, allVehicles);

    const typeOther = busTypes.find(findTypeOther);
    if (typeOther) {
      categories.push(convertTypeToFeaturedCategory({ ...typeOther, mainMenuDisplayOrder: 999 }));
    }

    if (!isLarge) {
      categories.push({
        id: FeaturedCategories.SEPARATOR,
        title: '',
        url: '',
        showOnMainMenu: true
      });
      categories.push({
        id: 'CONTACT_OPTION',
        title: t('NAVIGATION__CONTACT'),
        url: '/contact',
        showOnMainMenu: true
      });
      categories.push({
        id: 'SELL_BUS_OPTION',
        title: t('NAVIGATION__BUYERS_INFO'),
        url: '/sell_bus',
        showOnMainMenu: true
      });
    }
    return categories;
  }, [t, status, busTypes, isLarge]);

  const [searchTerm, setSearchTerm] = useState<string>('');

  const mainMenuCategories = useMemo(
    () => sortBy(featuredCategories.filter(cat => cat.showOnMainMenu), 'mainMenuDisplayOrder'),
    [featuredCategories]
  );

  useEffect(() => {
    const filter: VehicleFilter = convertSearchParamsIntoFilter(location.search, i18n.language);
    const term = filter.term || '';
    setSearchTerm(term);
  }, [i18n.language, location.search]);

  const [isMenuOpen, setIsMenuOpen] = useState(false);

  function handleSearch(term: string) {
    history.push(getLocalizedPath(`/vehicles?term=${term}`, i18n.language));
  }

  const HeaderContainer = isLarge ? ScHeader : ScHeaderCompact;

  return (
    <>
      <HeaderContainer>
        <ScBranding>
          <LocalizedLink to="/">
            <ScLogo src={bbs_logo} alt="Belgian Bus Company Logo" />
          </LocalizedLink>
          <div className="company_name">
            <LocalizedLink to="/">
              <div className="name">{companyInformation.name}</div>
              <div className="subtitle">{t('BBS_HEADER_SUBTITLE')}</div>
            </LocalizedLink>
          </div>
        </ScBranding>
        {isLarge ? (
          <>
            <ScMarginRight>
              <CategorySelector categories={featuredCategories} requestStatus={status} />
            </ScMarginRight>
            <ScBlankSpace />
            <ScMarginRight>
              <ScSearch>
                <Search onChange={setSearchTerm} value={searchTerm} onSearch={handleSearch} />
              </ScSearch>
            </ScMarginRight>
            <ScLanguageSelector />
          </>
        ) : (
          <>
            <ScLanguageSelectorCompact showCountryCode={true} />
            <ScMenuWrapper>
              <ScBlankSpace />
              <ScDivider type="vertical" />
              <ScIcon type="menu" onClick={() => setIsMenuOpen(!isMenuOpen)} />
            </ScMenuWrapper>
          </>
        )}
      </HeaderContainer>
      {isLarge ? (
        <ScInlineCategories showMargin={showMarginAfterMenu} categories={mainMenuCategories} />
      ) : (
        <>
          <ScVerticalList
            showMargin={showMarginAfterMenu}
            categories={mainMenuCategories}
            onClick={() => setIsMenuOpen(false)}
            isOpen={isMenuOpen}
            onClose={() => setIsMenuOpen(false)}
          />
          <ScSearchHeader>
            <Search onChange={setSearchTerm} value={searchTerm} onSearch={handleSearch} />
          </ScSearchHeader>
        </>
      )}
    </>
  );
};

export { Header };
