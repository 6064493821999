import { Layout } from 'antd';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';
import slugify from 'speakingurl';
import styled from 'styled-components';
import { Address } from '../components/Address';
import { FooterNavigation, FooterNavigationT } from '../components/FooterNavigation';
import LanguageSelector from '../components/LanguageSelector';
import { useConfiguration } from '../context/ConfigurationProvider';
import { usePages } from '../context/PagesHook';
import { BBS_PRIMARY_COLOR, BREAKPOINTS } from '../utils/constants';

type ScFooterProps = {
  hasMargin: boolean;
};

const ScFooter = styled(({ hasMargin, ...rest }) => <Layout.Footer {...rest} />)<ScFooterProps>`
  display: flex;
  flex-direction: row;
  background-color: ${BBS_PRIMARY_COLOR};
  background-image: url('/images/belgium-map.png');
  background-size: 500px;
  background-position: left;
  background-repeat: no-repeat;
  justify-content: space-between;
  flex-wrap: wrap;
  color: white;
  padding: 24px 50px 24px 50px;
  margin: ${props => (props.hasMargin ? '20px 0 0 0' : '0')};

  @media (max-width: ${BREAKPOINTS.sm}px) {
    padding: 15px;
  }

  a {
    color: white;
  }

  @media print {
    display: none;
  }
`;

const ScLanguageSelector = styled(LanguageSelector)`
  margin-top: 1rem;

  @media print {
    display: none;
  }
`;

const ScNavigationAndNewsletter = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media print {
    display: none;
  }
`;

const Footer: React.FC = () => {
  const { companyInformation, appStyle } = useConfiguration();
  const { t, i18n } = useTranslation();
  const { pagesWithoutContent } = usePages(i18n.language);
  const isLarge = useMediaQuery({ minWidth: BREAKPOINTS.lg });

  const [navigationItems, setNavigationItems] = useState<FooterNavigationT[]>([]);

  useEffect(() => {
    const navItems: FooterNavigationT[] = [
      { url: '/', description: t('NAVIGATION__HOME') },
      { url: '/contact', description: t('NAVIGATION__CONTACT') },
      { url: '/sell_bus', description: t('NAVIGATION__BUYERS_INFO') },
      { url: '/vehicles', description: t('BROWSE_VEHICLES') }
    ];

    navItems.splice(
      1,
      0,
      ...pagesWithoutContent.map<FooterNavigationT>(page => ({
        url: `/pages/${page.id}/${slugify(page.title)}`,
        description: page.title
      }))
    );

    setNavigationItems(navItems);
  }, [pagesWithoutContent, t]);

  return (
    <>
      <ScFooter hasMargin={appStyle.showMarginBeforeFooter}>
        {companyInformation && <Address {...companyInformation} />}
        <ScNavigationAndNewsletter>
          <FooterNavigation navigationItems={navigationItems} />
        </ScNavigationAndNewsletter>
        {!isLarge && (
          <>
            <div style={{ width: '100%' }}></div>
            <ScLanguageSelector />
          </>
        )}
      </ScFooter>
    </>
  );
};

export { Footer };
