import { faFacebook, faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { BBS_PRIMARY_COLOR } from '../utils/constants';

export type ShareProps = {
  subject: string;
  className?: string;
};

const ScShare = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  .share {
    font-size: 21px;
    font-weight: 300;
    color: #6f6f6f;
    width: 100%;
  }

  .icon {
    display: flex;
    margin-right: 7px;
    padding: 2px 7px;
    justify-content: center;
    font-size: 38px;
    width: 60px;
    color: ${BBS_PRIMARY_COLOR};
    border-radius: 2px;
  }

  .icon:hover {
    color: white;
    transition: 0.2s ease;
  }

  .icon-wp:hover {
    background-color: #24d366;
  }

  .icon-fb:hover {
    background-color: #1777f2;
  }
  .icon-email:hover {
    background-color: #97c2e0;
  }

  a {
    color: white;
  }
`;

const Share = ({ subject, className }: ShareProps) => {
  const { t } = useTranslation();
  return (
    <ScShare className={className}>
      <div className="share">{t('SHARE')}</div>
      <a
        target="_blank"
        rel="noopener noreferrer"
        href={`whatsapp://send?text=${window.location.href}`}
        data-action="share/whatsapp/share"
      >
        <div className="icon icon-wp">
          <FontAwesomeIcon icon={faWhatsapp} />
        </div>
      </a>
      <a
        target="_blank"
        rel="noopener noreferrer"
        href={`https://www.facebook.com/sharer/sharer.php?u=${window.location.href}`}
      >
        <div className="icon icon-fb">
          <FontAwesomeIcon icon={faFacebook} />
        </div>
      </a>
      <a
        target="_blank"
        rel="noopener noreferrer"
        href={`mailto:?subject=${subject}&body=${window.location.href}`}
      >
        <div className="icon icon-email">
          <FontAwesomeIcon icon={faEnvelope} />
        </div>
      </a>
    </ScShare>
  );
};

export { Share };
