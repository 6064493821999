import React, { ReactChild } from 'react';
import Helmet from 'react-helmet';

const bbs_logo = `${window.location.origin}/images/bbs_logo.gif`;

export type MetaTagsProps = {
  title: string;
  ogUrl?: string;
  ogImage?: string;
  description?: string;
  children?: ReactChild;
};

export const MetaTags = ({
  title,
  description,
  ogUrl = window.location.href,
  ogImage = bbs_logo,
  children
}: MetaTagsProps) => {
  return (
    <Helmet>
      <title>{title}</title>
      {description && <meta name="description" content={description} />}
      <meta property="og:url" content={ogUrl} />
      <meta property="og:title" content={title} />
      {ogImage && <meta property="og:image" content={ogImage} />}
      {ogImage && <meta property="og:image:width" content="350" />}
      {ogImage && <meta property="og:image:height" content="200" />}
      {ogImage && <meta property="og:image:secure_url" content={ogImage} />}
      {children}
    </Helmet>
  );
};
