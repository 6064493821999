import { Page } from '@hypercharge/bbs-website-commons/lib/types/bbs';
import { orderBy } from 'lodash';
import { useEffect, useState } from 'react';
import { failOnNullOrError, hyperfetch } from '../utils/httpClient';
import { Status } from '../utils/types';

const getAllPagesWithoutContent = (languageCode: string): Promise<Page[]> => {
  return failOnNullOrError(
    hyperfetch<Page[]>(`/api/get-pages/${languageCode}?includeContent=false`, {
      method: 'GET'
    })
  );
};

const getPage = (id: string, languageCode: string): Promise<Page> => {
  return failOnNullOrError(
    hyperfetch<Page>(`/api/get-pages/${languageCode}/${id}?includeContent=true`, {
      method: 'GET'
    })
  );
};

const usePages = (languageCode: string) => {
  const [pagesWithoutContent, setPagesWithoutContent] = useState<Page[]>([]);
  const [status, setStatus] = useState<Status>(Status.Loading);

  useEffect(() => {
    setStatus(Status.Loading);

    getAllPagesWithoutContent(languageCode)
      .then(pages => {
        setPagesWithoutContent(pages);
        setStatus(Status.Success);
      })
      .catch(e => setStatus(Status.Error));
  }, [languageCode]);

  return { pagesWithoutContent, status };
};

export { usePages, getPage };
