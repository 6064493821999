import { Axle } from '@hypercharge/bbs-website-commons/lib/types/bbs';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

export type TiresProp = {
  axles: Axle[];
  className?: string;
};

const ScTable = styled.table`
  font-size: 0.8rem;
  @media print {
    font-size: 10px;
  }
  th,
  td {
    min-width: 70px;
    text-align: center;
  }

  th:first-child,
  td:first-child {
    min-width: initial;
    text-align: initial;
    padding-right: 10px;
  }
`;

const Tires = ({ axles, className }: TiresProp) => {
  const { t } = useTranslation();
  return (
    <ScTable className={className}>
      <thead>
        <tr>
          <th></th>
          {axles.map((axle, i) => (
            <th key={i}>
              {axle.description.startsWith('__')
                ? t(`VEHICLE__TIRES${axle.description}`)
                : axle.description}
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>{t('VEHICLE__TIRES_LEFT')}</td>
          {axles.map((axle, i) => (
            <td key={i}>{axle.left ? axle.left : '--'}</td>
          ))}
        </tr>
        <tr>
          <td>{t('VEHICLE__TIRES_RIGHT')}</td>
          {axles.map((axle, i) => (
            <td key={i}>{axle.right ? axle.right : '--'}</td>
          ))}
        </tr>
      </tbody>
    </ScTable>
  );
};

export { Tires };
