import { SortOrder } from '@hypercharge/lambda-utils/lib/search/searchTypes';
import { VehicleStatus } from './types/bbs';
export var TENANT_ID = 'bbs';
export var ERROR_FAILED_RECAPTCHA = 'ERROR_FAILED_RECAPTCHA';
export var NEWSLETTER_EMAIL_ALREADY_EXISTS = 'NEWSLETTER_EMAIL_ALREADY_EXISTS';
export var SEARCH_VEHICLES_AVAILABLE_ORDERING = ['createdAt', 'model', 'salesPrice'];
export var SEARCH_VEHICLES_DEFAULT_ORDER = 'salesPrice';
export var SEARCH_VEHICLES_DEFAULT_ORDER_TYPE = SortOrder.desc;
export var SEARCH_VEHICLES_DEFAULT_RESULTS = 10;
export var SEARCH_RELATED_VEHICLE_AROUND_PRICE = 15000;
export var SEARCH_RELATED_VEHICLE_AROUND_PRICE_130 = 45000;
export var DEFINITION_ID_BANNER = 'websiteBanner';
export var DEFINITION_ID_BUS = 'bus';
export var DEFINITION_ID_BUS_BRAND = 'busBrand';
export var DEFINITION_ID_BUS_ENGINE = 'busBrand2';
export var DEFINITION_ID_BUS_MODEL = 'model';
export var DEFINITION_ID_BUS_TYPE = 'busType';
export var DEFINITION_ID_COLOR = 'busColour';
export var DEFINITION_ID_EXHAUST = 'busExhaust';
export var DEFINITION_ID_FORM_CONTACT = 'websiteFormsContact';
export var DEFINITION_ID_FORM_SELL = 'websiteFormsSell';
export var DEFINITION_ID_FUEL = 'busFuel';
export var DEFINITION_ID_GEARBOX = 'busGear';
export var DEFINITION_ID_NEWSLETTER_SUBSCRIPTION = 'websiteNewsletterSubscription';
export var DEFINITION_ID_PAGES = 'websiteStaticPages';
export var PROPERTY_ID_SYSTEM_CREATED_AT = 'createdAt';
export var PROPERTY_ID_SYSTEM_DOMAIN = 'entityDomain';
export var PROPERTY_ID_SYSTEM_ENTITY = 'entityId';
export var PROPERTY_ID_SYSTEM_OWNER = 'entityOwner';
export var PROPERTY_ID_SYSTEM_TITLE = 'title';
export var PROPERTY_ID_META_LABELS = 'labels';
export var PROPERTY_ID_BUS_AVAILABLE_SEATS = 'availableSeats';
export var PROPERTY_ID_BUS_STANDING_PLACES = 'standingPlaces';
export var PROPERTY_ID_BUS_AXLE_3D_L = 'n3DAxleL';
export var PROPERTY_ID_BUS_AXLE_3D_R = 'n3DAxleR';
export var PROPERTY_ID_BUS_AXLE_BC_L = 'backL';
export var PROPERTY_ID_BUS_AXLE_BC_R = 'backR';
export var PROPERTY_ID_BUS_AXLE_FR_L = 'frontL';
export var PROPERTY_ID_BUS_AXLE_FR_R = 'frontR';
export var PROPERTY_ID_BUS_BRAND = 'brand';
export var PROPERTY_ID_BUS_ENGINE = 'motor2';
export var PROPERTY_ID_BUS_EURONORM = 'exhaust';
export var PROPERTY_ID_BUS_FUEL = 'fuel';
export var PROPERTY_ID_BUS_GEARBOX = 'gear';
export var PROPERTY_ID_BUS_HIGHLIGHTED_VEHICLE = 'highlightedVehicle';
export var PROPERTY_ID_BUS_HP = 'pk';
export var PROPERTY_ID_BUS_IMAGES_PRINTING = 'picturesForPrinting';
export var PROPERTY_ID_BUS_IMAGES_WEBSITE = 'picturesExternal';
export var PROPERTY_ID_BUS_KM = 'km';
export var PROPERTY_ID_BUS_KW = 'kw';
export var PROPERTY_ID_BUS_MODEL = 'model';
export var PROPERTY_ID_BUS_PRICE_VAT = 'salesPriceInclVat';
export var PROPERTY_ID_BUS_REF_NUMBER = 'internalBusNumber';
export var PROPERTY_ID_BUS_RENTING_END = 'rentingEnd';
export var PROPERTY_ID_BUS_RENTING_START = 'rentingStart';
export var PROPERTY_ID_BUS_RETARDER = 'retarder';
export var PROPERTY_ID_BUS_SALES_PRICE = 'salesPrice';
export var PROPERTY_ID_BUS_SALES_PRICE_VAT = 'salesPriceInclVat';
export var PROPERTY_ID_BUS_SEARCH_FIELD = 'titleForSearch';
export var PROPERTY_ID_BUS_SEATS = 'fixedSeats';
export var PROPERTY_ID_BUS_SEATS_EXTRA = 'extraSeats';
export var PROPERTY_ID_BUS_SEATS_MAXIMUM_CAP = 'maximumCapacity';
export var PROPERTY_ID_BUS_SEATS_NEXT_DRIVER = 'seatsNextToDriver';
export var PROPERTY_ID_BUS_SEATS_STANDING = 'standingPlaces';
export var PROPERTY_ID_BUS_SEATS_WHEELCHAIR = 'wheelchairPlaces';
export var PROPERTY_ID_BUS_SOLD_AT = 'soldAt';
export var PROPERTY_ID_BUS_STATUS = 'status';
export var PROPERTY_ID_BUS_SUBTITLE = 'subtitle';
export var PROPERTY_ID_BUS_TYPE = 'type';
export var PROPERTY_ID_BUS_YEAR = 'year';
export var PROPERTY_ID_BUS_TYPE_DESCRIPTION = 'description'; //Multilanguage property
export var PROPERTY_ID_BUS_TYPE_ENABLED = 'isEnabled';
export var PROPERTY_ID_BANNER_DESCRIPTION = 'description';
export var PROPERTY_ID_BANNER_CALL_TO_ACTION = 'callToAction';
export var PROPERTY_ID_BANNER_ORDER = 'order';
export var PROPERTY_ID_BANNER_ENABLED = 'isEnabled';
export var PROPERTY_ID_PAGES_CONTENT = 'content';
export var PROPERTY_ID_PAGES_ENABLED = 'isEnabled';
export var PROPERTY_ID_PAGES_ORDER_BY = 'orderAscending';
export var SECTION_ID_BUS_TECHNICAL_DETAILS = 'technicalDetails';
export var SECTION_ID_BUS_EQUIPMENT = 'equipement';
// https://www.twilio.com/docs/glossary/what-e164
// https://www.itu.int/rec/T-REC-E.164/en
export var E164_PHONE_NUMBER_REGEX = /^\+[1-9]\d{1,14}$/;
export var LANGUAGE_CODE_REGEX = /^[a-z]{2}$/;
// this used to be fetched dynamically but it doesn't change
// frequent enough to justify the extra request every time
export var FALLBACK_LANGUAGE = 'en';
export var AVAILABLE_LANGUAGES = ['nl', 'fr', 'en', 'pl', 'pl', 'de', 'ru'];
// rules to change UI/results based on status
export var STATUSES_ONLINE = [
    VehicleStatus.AVAILABLE,
    VehicleStatus.RESERVED,
    VehicleStatus.RESERVED_PREPAID,
    VehicleStatus.SOLD,
    VehicleStatus.RENTED
];
export var STATUSES_WITH_PRICE_VISIBLE = [
    VehicleStatus.AVAILABLE,
    VehicleStatus.RESERVED,
    VehicleStatus.RESERVED_PREPAID
];
