import { Input } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';

const { Search: AntdSearch } = Input;

type SearchProps = {
  onSearch?: (value: string) => void;
  onChange?: (value: string) => void;
  value: string;
  showSearchButton?: boolean;
  className?: string;
};

const Search: React.FC<SearchProps> = ({
  onSearch,
  onChange,
  showSearchButton = false,
  className,
  value = ''
}) => {
  const { t } = useTranslation();
  return (
    <AntdSearch
      placeholder={t('SEARCH__PLACEHOLDER')}
      onSearch={onSearch}
      onChange={e => onChange && onChange(e.currentTarget.value)}
      enterButton={showSearchButton}
      className={className}
      value={value}
    ></AntdSearch>
  );
};

export { Search };
