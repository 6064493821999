import { Button, Dropdown, Icon, Menu } from 'antd';
import { upperCase } from 'lodash';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { changeLanguage, LANGUAGE_OPTIONS } from '../context/i18n';
import { Status } from '../utils/types';

const ScDropdown = styled(Dropdown)`
  color: white;
  user-select: none;
  :disabled {
    color: white;
  }
`;

const ScButton = styled(Button)`
  padding: 8px 0;
  && span + .anticon {
    margin-left: 2px;
  }
`;

const LanguageSelector = ({
  className,
  showCountryCode = false
}: {
  className?: string;
  showCountryCode?: boolean;
}) => {
  const { i18n } = useTranslation();
  const history = useHistory();
  const [status, setStatus] = useState<Status>(Status.Idle);

  const currentLanguage = LANGUAGE_OPTIONS.find(v => v.value === i18n.language);

  const menu = () => {
    return (
      <Menu>
        {LANGUAGE_OPTIONS.map(language => {
          return (
            <Menu.Item
              key={language.value}
              onClick={async () => {
                setStatus(Status.Loading);
                await changeLanguage(history, language.value);
                setStatus(Status.Idle);
              }}
            >
              {language.label}
            </Menu.Item>
          );
        })}
      </Menu>
    );
  };

  return (
    <ScDropdown className={className} overlay={menu} disabled={status === Status.Loading}>
      <ScButton type="link" className="ant-dropdown-link">
        {currentLanguage &&
          (showCountryCode ? upperCase(currentLanguage.value) : currentLanguage.label)}
        {status === Status.Loading ? <Icon type="loading" /> : <Icon type="down" />}
      </ScButton>
    </ScDropdown>
  );
};

export default LanguageSelector;
