import { Icon } from 'antd';
import React from 'react';
import styled from 'styled-components';
import { CompanyInformation } from '../context/ConfigurationProvider';

export type AddressProps = {
  showCompanyName?: boolean;
  className?: string;
} & CompanyInformation;

const ScAddress = styled.address`
  display: flex;
  flex-direction: column;
  div {
    line-height: 1.5em;
    font-size: 1em;
  }

  @media print {
    font-size: 10px;
    div {
      line-height: 14px;
    }
  }
`;

const ScCompany = styled.span`
  font-size: 1.5em;
  font-weight: 800;
  text-transform: uppercase;
  @media print {
    display: none;
  }
`;

const ScIcon = styled(Icon)`
  margin-right: 5px;
  @media print {
    color: black;
  }
`;

const ScTransparentIcon = styled(ScIcon)`
  visibility: hidden;
`;

const Address = ({
  showCompanyName = true,
  address1,
  address2,
  phoneNumber,
  email,
  name,
  className
}: AddressProps) => {
  return (
    <ScAddress className={className}>
      {showCompanyName && <ScCompany>{name}</ScCompany>}
      <div>
        <ScIcon type="compass" />
        {address1}
      </div>
      <div>
        <ScTransparentIcon type="compass" />
        {address2}
      </div>
      <div>
        <ScIcon type="phone" />
        <a href={`tel:${phoneNumber}`}>{phoneNumber}</a>
      </div>
      <div>
        <ScIcon type="mail" />
        <a href={`mailto:${email}`}>{email}</a>
      </div>
    </ScAddress>
  );
};

export { Address };
