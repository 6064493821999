import { FeatureType, Vehicle } from '@hypercharge/bbs-website-commons/lib/types/bbs';
import QRCode from 'qrcode';
import moment from 'moment';
import React, { useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import BBSPrintLogo from '../assets/images/bbs_header_print.gif';
import { Attributes } from '../components/vehicle/Attributes';
import { Tires } from '../components/vehicle/Tires';
import { useConfiguration } from '../context/ConfigurationProvider';
import { getFormattedPrice } from '../utils/helpers';
import { PageDescriptionT } from '../utils/types';

const printDateFormat = 'DD/MM/YYYY';

const ScWrapper = styled.div`
  display: block;

  h1 {
    text-align: center;
    margin: 0;
    color: #0847b2;
    font-size: 14px;
  }
  
  h2 {
    text-align: center;
    margin: 0;
    color: #000;
    font-size: 12px;
  }

  h3 {
    text-decoration: underline;
    margin-top: 10px;
    font-size: 16px;
  }

  p {
    color: #000;
    font-size: 13px;
  }
`;

const ScHeader = styled.img`
  width: 100%;
`;

const ScImageHolder = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin: 0 auto;

  img {
    width: 50%;
  }
`;

const ScVehicleTires = styled(Tires)`
  min-width: 280px;
  color: #000;
  font-size: 13px;
`;

const ScAttributes = styled(Attributes)`
  color: #000;
  font-size: 13px;
`;

const ScFooter = styled.div`
  background-color: #0847b2;
  padding: 10px 0;
  display: flex;
  flex-direction: row;
  justify-content: center;

  div {
    margin-right: 20px;

    p {
      font-size: 11px;
      line-height: 13px;
      margin-bottom: 2px;
      color: #000;
    }
  }
  
  .printDate {
    position: absolute;
    bottom: 1rem;
    right: 0;
  }
  
  @page {
    size: auto;
    margin: 1rem auto;
  }
`;

const ScCanvasWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

const ScQRCodeMessage = styled.p`
  text-align: center;
  margin: 30px 0 0 0;
`;

const VehiclePrint = ({
                        vehicle,
                        onPageDescription,
                        className = ''
                      }: {
  vehicle: Vehicle;
  className?: string;
} & PageDescriptionT) => {
  const { t } = useTranslation();
  const { companyInformation } = useConfiguration();

  const { euronorm, equipments, imagesUrls } = useMemo(() => {
    const euronorm = vehicle.features.find(feature => feature.type === FeatureType.EURONORM);

    let imagesUrls: string[] = [];
    if (vehicle.imagesForPrinting.length > 0) {
      imagesUrls = [...vehicle.imagesForPrinting].splice(0, 2);
    } else if (vehicle.carrouselImages) {
      imagesUrls = [...vehicle.carrouselImages].splice(0, 2).map(c => c.fullImageUrl);
    }

    return {
      euronorm: euronorm ? euronorm.description : '',
      equipments: vehicle.equipments.map(e => e.key).join(', '),
      imagesUrls
    };
  }, [vehicle]);

  const technicalAttrs = useMemo(() => {
    const visibleTechnicalAttrs = [
      ...vehicle.technicalAttrs,
      { key: t('SEATS'), value: vehicle.summary.seatsSummary }
    ];
    if (vehicle.summary.standingPlaces != null) {
      visibleTechnicalAttrs.push({
        key: t('STANDING_PLACES'),
        value: `${vehicle.summary.standingPlaces}`
      });
    }
    return visibleTechnicalAttrs;
  }, [vehicle, t]);

  useEffect(() => {
    if (vehicle && onPageDescription) {
      onPageDescription(vehicle.summary.description);
    }
  }, [onPageDescription, vehicle]);

  const qrCodeCanvasRef = useCallback(canvas => {
    if (!canvas) return;

    QRCode.toCanvas(canvas, window.location.href, { width: 100 });
  }, []);

  return (
    <ScWrapper className={className}>
      <ScHeader src={BBSPrintLogo} alt="BBS Logo" />
      <h1>
        {vehicle.summary.description} - {euronorm}
      </h1>

      <h2>
        {vehicle.summary.subtitle}
      </h2>

      <h1>{vehicle.summary.price && getFormattedPrice(vehicle.summary.price)}</h1>
      <hr />
      <ScImageHolder>
        {imagesUrls.map((url, i) => {
          return <img key={i} src={url} alt="A Bus" />;
        })}
      </ScImageHolder>
      <h3>{t('VEHICLE__TECHNICAL_DETAILS')}</h3>
      <ScAttributes attributes={technicalAttrs} />
      <h3>{t('VEHICLE__TIRES')}</h3>
      {vehicle.tires.length > 0 && <ScVehicleTires axles={vehicle.tires} />}
      <h3>{t('VEHICLE__EQUIPMENT')}</h3>
      <p>{equipments}</p>
      <ScQRCodeMessage>{t('VEHICLE_PRINT__QRCODE_MESSAGE')}</ScQRCodeMessage>
      <ScCanvasWrapper>
        <canvas ref={qrCodeCanvasRef}></canvas>
      </ScCanvasWrapper>
      <ScFooter>
        <div>
          <p>{companyInformation.name}</p>
          <p>{companyInformation.address1}</p>
          <p>{companyInformation.address2}</p>
        </div>
        <div>
          <p>E {companyInformation.email}</p>
          <p>T {companyInformation.phoneNumber}</p>
          <p>F {companyInformation.faxNumber}</p>
        </div>
        <div className="printDate">{t('DATE_PRINTED')}: {moment().format(printDateFormat)}</div>
      </ScFooter>
    </ScWrapper>
  );
};

export { VehiclePrint };
