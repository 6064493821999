import { KeyValueT } from '@hypercharge/bbs-website-commons/lib/types/bbs';
import { Icon } from 'antd';
import React from 'react';
import styled from 'styled-components';
import { BREAKPOINTS } from '../../utils/constants';

type EquipmentProps = {
  equipments: KeyValueT[];
};

const ScWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  @media print {
    display: none;
  }
`;

const ScItem = styled.div`
  width: 100%;

  @media screen and (min-width: ${BREAKPOINTS.sm}px) {
    width: 50%;
  }

  @media screen and (min-width: ${BREAKPOINTS.md}px) {
    width: 25%;
  }

  @media print {
    width: 25%;
    font-size: 10px;
  }

  i {
    margin-right: 5px;
    color: #52c41a;
  }
`;

const Equipment = ({ equipments }: EquipmentProps) => {
  return (
    <ScWrapper>
      {equipments.map((equipment, i) => {
        return (
          <ScItem key={i}>
            <Icon type="check" />
            {equipment.key}
          </ScItem>
        );
      })}
    </ScWrapper>
  );
};
export { Equipment };
