import { SEARCH_VEHICLES_AVAILABLE_ORDERING } from '@hypercharge/bbs-website-commons/lib/constants';
import { Pagination, VehicleSummary } from '@hypercharge/bbs-website-commons/lib/types/bbs';
import { SortOrder } from '@hypercharge/lambda-utils/lib/search/searchTypes';
import { Button, Divider, Empty, Pagination as PaginationAntd } from 'antd';
import { parseUrl, stringify } from 'query-string';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { getLocalizedPath } from '../../context/i18n';
import { BREAKPOINTS, QUERY_OPTIONS } from '../../utils/constants';
import LocalizedLink from '../LocalizedLink';
import { VehicleCardRow, VehicleCardRowSkeleton } from '../VehicleCard';
import { Sorting, SortingProps } from './Sorting';

export type VehiclesResultsProps = {
  pagination: Pagination;
  results: VehicleSummary[];
  className?: string;
  ordering: {
    orderBy: string;
    orderByType: SortOrder;
    setOrderTo: (orderBy: string, orderByType: SortOrder) => void;
  };
  onChangePage?: (destinationPageNumber: number, resultsPerPage: number) => void;
  clearAllFilters?: () => void;
};

const ScWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const ScSummaryAndSorting = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-size: 0.8rem;
  line-height: 2rem;
  background-color: rgba(11, 119, 192, 0.05);
  margin-bottom: 1rem;
  padding: 0 10px 0 10px;
  
  @media print {
    display: none;
  }
`;

const ScCenter = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
`;

const ScSummaryMessage = styled.span`
  font-size: 0.8rem;
  color: #787878;
  margin-bottom: 5px;
`;

const ScVehicleCardRow = styled(VehicleCardRow)`
  padding: 0 1.5rem;
  @media screen and (max-width: ${BREAKPOINTS.md}px) {
    padding: 0 8px;
  }
`;

const ScEmptyWrapper = styled.div`
  padding: 10px 0 50px 0;

  .CTAs {
    display: flex;
    flex-direction: row;
    justify-content: center;

    button {
      margin-right: 10px;
    }
  }
`;

const ScHideOnPrint = styled.div`
  @media print {
    display: none;
  }
`;

const ScPrint25Div = styled.div`
 @media print and (orientation: landscape) {
    height: 33vh;
    page-break-inside: avoid;
 }
 @media print and (orientation: portrait) {
    height: 25vh;
    page-break-inside: avoid;
 }
`;

const VehiclesResults = ({
                           pagination,
                           className,
                           results,
                           ordering,
                           onChangePage,
                           clearAllFilters
                         }: VehiclesResultsProps) => {
  const {
    t,
    i18n: { language }
  } = useTranslation();
  const history = useHistory();
  const location = useLocation();

  const sortingOptions = useMemo(() => {
    const sorting: SortingProps['sortingOptions'] = [];
    SEARCH_VEHICLES_AVAILABLE_ORDERING.forEach(orderBy => {
      const description = t('SEARCH_VEHICLES_ORDER_FIELD_' + orderBy.toUpperCase());
      sorting.push({
        description: `${description} - ${t('SEARCH_VEHICLES_ORDER_FIELD_ASC')}`,
        orderBy,
        orderByType: SortOrder.asc
      });
      sorting.push({
        description: `${description} - ${t('SEARCH_VEHICLES_ORDER_FIELD_DESC')}`,
        orderBy,
        orderByType: SortOrder.desc
      });
    });
    return sorting;
  }, [t]);

  const summaryMessage = t('VEHICLES_RESULTS__RESULTS_SUMMARY', {
    from: (
      pagination.currentPage * pagination.resultsPerPage -
      (pagination.resultsPerPage - 1)
    ).toLocaleString(),
    to: (pagination.currentPage * pagination.resultsPerPage > pagination.resultsCount
        ? pagination.resultsCount
        : pagination.currentPage * pagination.resultsPerPage
    ).toLocaleString(),
    total: pagination.resultsCount.toLocaleString()
  });

  return (
    <ScWrapper className={className}>
      <ScSummaryAndSorting>
        <div>
          {pagination.resultsCount.toLocaleString()}
          {t('VEHICLES_RESULTS__RESULTS_COUNT')}
        </div>
        {pagination.resultsCount > 0 && (
          <Sorting
            sortingOptions={sortingOptions}
            selectedOrder={ordering.orderBy}
            selectedOrderType={ordering.orderByType}
            onChange={ordering.setOrderTo}
          />
        )}
      </ScSummaryAndSorting>
      <div>
        {results.map(vehicle => (
          <ScPrint25Div key={vehicle.id}>
            <ScVehicleCardRow key={vehicle.id} {...vehicle} />
            <Divider />
          </ScPrint25Div>
        ))}
      </div>
      {pagination.resultsCount > 0 ? (
        <ScHideOnPrint>
          <ScCenter>
            <PaginationAntd
              current={pagination.currentPage}
              pageSize={pagination.resultsPerPage}
              total={pagination.resultsCount}
              onChange={() => {
                window.scroll(0, 0);
              }}
              onShowSizeChange={(currentSize, newSize) => {
                if (!onChangePage) return;

                window.scroll(0, 0);
                onChangePage(1, newSize);
              }}
              pageSizeOptions={['10', '20', '40', pagination.resultsCount.toString()]}
              showSizeChanger={true}
              itemRender={(page, type) => {
                const isFirstPage = pagination.currentPage === 1;
                const isLastPage = pagination.currentPage === pagination.totalPages;
                const isPrevButton = type === 'prev';
                const isNextButton = type === 'next';

                const searchParams = { ...parseUrl(location.search, QUERY_OPTIONS).query };

                searchParams.page = page.toString();

                let pageIndicator: string = page.toString();

                if (isNextButton) {
                  pageIndicator = '>';
                }

                if (isPrevButton) {
                  pageIndicator = '<';
                }

                return <PaginationButton content={pageIndicator}
                                         isLink={!((isFirstPage && isPrevButton) || (isLastPage && isNextButton))}
                                         url={location.pathname + '?' + stringify(searchParams, QUERY_OPTIONS)} />;
              }}
            />
          </ScCenter>
          <ScCenter>
            <ScSummaryMessage>{summaryMessage}</ScSummaryMessage>
          </ScCenter>
        </ScHideOnPrint>
      ) : (
        <ScEmptyWrapper>
          <Empty
            description={t('SEARCH_VEHICLES_NO_RESULTS')}
            style={{}}
            image={Empty.PRESENTED_IMAGE_SIMPLE}
          />
          <div className="CTAs">
            <Button
              type="primary"
              onClick={() => history.push(getLocalizedPath('/contact?missingVehicle', language))}
            >
              {t('NAVIGATION__CONTACT')}
            </Button>
            <Button type="primary" onClick={() => history.goBack()}>
              {t('NAVIGATION__BACK')}
            </Button>
            <Button type="primary" onClick={() => clearAllFilters && clearAllFilters()}>
              {t('VEHICLES_FILTER__CLEAR')}
            </Button>
          </div>
        </ScEmptyWrapper>
      )}
    </ScWrapper>
  );
};

const PaginationButton = ({ isLink = false, url, content }: { isLink: boolean; url: string; content: string; }) => {
  return !isLink ?
    (<div className="ant-pagination-item-link">
      {content}
    </div>)
    : <LocalizedLink className="ant-pagination-item-link"
                     to={url}>
      {content}
    </LocalizedLink>;
};

const VehiclesResultsSkeleton = props => {
  return (
    <ScWrapper className={props.className}>
      <VehicleCardRowSkeleton />
      <Divider />
      <VehicleCardRowSkeleton />
      <Divider />
      <VehicleCardRowSkeleton />
    </ScWrapper>
  );
};

export { VehiclesResults, VehiclesResultsSkeleton };
