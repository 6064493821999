import { Banner as BannerT } from '@hypercharge/bbs-website-commons/lib/types/bbs';
import { Layout, notification } from 'antd';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';
import styled from 'styled-components';
import { Banner, BannerSkeleton, BannerType } from '../components/Banner';
import { LayoutFixedWidthWrapper } from '../components/LayoutFixedWidthWrapper';
import LocalizedLink from '../components/LocalizedLink';
import { NewsletterSubscribe } from '../components/NewsletterSubscribe';
import { RelatedVehicles } from '../components/RelatedVehicles';
import { StoryBanner, StoryBannerSkeleton } from '../components/StoryBanner';
import { useActiveBanners } from '../context/BannersHook';
import { useConfiguration } from '../context/ConfigurationProvider';
import { useLatestVehicles } from '../context/LatestVehicleHook';
import { subscribeToNewsletter } from '../context/NewsletterForm';
import { BBS_PRIMARY_COLOR, BREAKPOINTS } from '../utils/constants';
import { RequestState, Status } from '../utils/types';
import { SellCTA } from './SellBus';
import { MetaTags } from '../components/MetaTags';

const ScLayoutWrapper = styled(Layout.Content)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const ScLayoutFixedWidthWrapper = styled(LayoutFixedWidthWrapper)`
  @media screen and (max-width: ${BREAKPOINTS.sm}px) {
    padding: 0 10px 0 10px;
  }
`;

const ScHeaderBanner = styled(Banner)`
  margin: 0 0 1rem 0;
`;
const ScStoryBanner = styled(StoryBanner)`
  margin: 0 0 1rem 0;
`;

const ScNewsletterWrapper = styled.div`
  background-color: #f0f6fc;
  width: 100%;
  min-height: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 4rem;
  @media screen and (max-width: ${BREAKPOINTS.md}px) {
    padding: 2rem 1rem;
  }
`;

const ScLatestTitle = styled.div`
  color: #6f6f6f;
  font-weight: 400;
  margin-top: 10px;
  margin-bottom: 0;
  text-align: center;
  font-size: 1.5rem;
`;

const ScSeeAll = styled.h4`
  text-align: right;
  margin: 0.5rem 0 1rem 0;
  color: ${BBS_PRIMARY_COLOR};
`;

const renderHeaderBanners = (banners: BannerT[]) => {
  return banners
    .filter(banner => banner.group.entityId === BannerType.header)
    .map(banner => <ScHeaderBanner key={banner.entityId} banner={banner} />);
};

const renderStoryBanners = (banners: BannerT[]) => {
  return banners
    .filter(banner => banner.group.entityId === BannerType.story)
    .map(banner => <ScStoryBanner key={banner.entityId} banner={banner} />);
};

const Home = () => {
  const { t, i18n } = useTranslation();
  const vehicles = useLatestVehicles({ limit: 4, languageCode: i18n.language, singleImage: true });
  const highlightedVehicles = useLatestVehicles({
    limit: 4,
    languageCode: i18n.language,
    singleImage: true,
    onlyHighlightedVehicles: true
  });
  const { banners, status } = useActiveBanners({ languageCode: i18n.language });
  const isLarge = useMediaQuery({ minWidth: BREAKPOINTS.lg });
  const { appStyle, setAppStyle } = useConfiguration();

  const onSubscribe = async (email: string) => {
    const { requestState } = await subscribeToNewsletter(email);

    if (requestState === RequestState.ERROR) {
      const message = {
        message: t('NEWSLETTER_SUBSCRIBE_ERROR'),
        description: ''
      };

      notification.error({ ...message, duration: 0 });
      return false;
    }

    if (requestState === RequestState.SUCCESS) {
      notification.success({ message: t('NEWSLETTER_SUBSCRIBE_SUCCESS') });
    }
    return true;
  };

  useEffect(() => {
    if (!appStyle.showMarginAfterMenu || appStyle.showMarginBeforeFooter) {
      setAppStyle({ ...appStyle, showMarginAfterMenu: true, showMarginBeforeFooter: false });
    }
  }, [appStyle, setAppStyle]);

  return (
    <>
      <MetaTags title={t('HOME')} />
      {isLarge ? <SellCTA /> : null}
      <ScLayoutWrapper>
        <ScLayoutFixedWidthWrapper>
          {status === Status.Loading ? <BannerSkeleton /> : renderHeaderBanners(banners)}
          {highlightedVehicles && highlightedVehicles.length > 0 && (
            <ScLatestTitle>{t('HIGHLIGHTED_VEHICLES')}</ScLatestTitle>
          )}
          <RelatedVehicles vehicles={highlightedVehicles} />
          {vehicles && vehicles.length > 0 && <ScLatestTitle>{t('LATEST_VEHICLES')}</ScLatestTitle>}
          <RelatedVehicles vehicles={vehicles} />
          {vehicles && vehicles.length > 0 && (
            <LocalizedLink to="/vehicles">
              <ScSeeAll>{t('NAVIGATION__ALL_BUSES')}</ScSeeAll>
            </LocalizedLink>
          )}
          {status === Status.Loading ? <StoryBannerSkeleton /> : renderStoryBanners(banners)}
        </ScLayoutFixedWidthWrapper>
        <ScNewsletterWrapper>
          <NewsletterSubscribe onSubscribe={onSubscribe} />
        </ScNewsletterWrapper>
      </ScLayoutWrapper>
    </>
  );
};

export { Home };
