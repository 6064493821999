import { VehicleSummary } from '@hypercharge/bbs-website-commons/lib/types/bbs';
import { useEffect, useState } from 'react';
import { failOnNullOrError, hyperfetch } from '../utils/httpClient';
import { enhanceVehicleSummaries } from './VehicleHook';

type RelatedVehiclesT = {
  vehicleId: string;
  languageCode: string;
};

async function getRelatedVehiclesSummaries({
  vehicleId,
  languageCode
}: RelatedVehiclesT): Promise<VehicleSummary[]> {
  return failOnNullOrError(
    hyperfetch<VehicleSummary[]>(`/api/get-related-vehicles/${vehicleId}/${languageCode}`, {
      method: 'GET'
    })
  );
}

const useRelatedVehicles = (vehicleId: string | null, languageCode: string) => {
  const [summaries, setSummaries] = useState<VehicleSummary[] | null>(null);
  const [failedToFetchRelatedVehicles, setFailedToFetchRelatedVehicles] = useState<boolean>(false);

  useEffect(() => {
    if (!vehicleId) return;

    getRelatedVehiclesSummaries({ vehicleId, languageCode })
      .then(summaries => enhanceVehicleSummaries(summaries, { height: 200 }))
      .then(summaries => {
        setFailedToFetchRelatedVehicles(false);
        setSummaries(summaries);
      })
      .catch(e => {
        console.error(e);
        setFailedToFetchRelatedVehicles(true);
        setSummaries(null);
      });
  }, [vehicleId, languageCode]);

  return { summaries, failedToFetchRelatedVehicles };
};

export { useRelatedVehicles };
