import { Divider, Icon } from 'antd';
import React, { useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import styled from 'styled-components';

export type WrapperProps = {
  category: string;
  categoryId: string;
  startOpen?: boolean;
  onCollapse?: (isClosed: boolean) => void;
};

export type WrapperPropsChild = {
  children: React.ReactNode;
};

const ScFilterDescription = styled.div`
  font-size: 1em;
  color: #3f3e3e;
  margin-bottom: 5px;

  button {
    font-weight: 500;
    background: none;
    border: 0;
    margin: 0;
    padding: 0;
    i {
      margin-left: 5px;
      font-size: 0.8em;
    }
  }
`;

const ScDivider = styled(Divider)`
  &&& {
    margin: 10px 0;
  }
`;

const ScInternal = styled.div`
  padding: 0 10px 0 10px;
`;

const Wrapper = ({ category, startOpen = true, children }: WrapperProps & WrapperPropsChild) => {
  const [isOpen, setIsOpen] = useState(startOpen);
  return (
    <>
      <ScInternal>
        <ScFilterDescription>
          <button onClick={() => setIsOpen(!isOpen)}>
            {category}
            <Icon type={isOpen ? 'down' : 'right'} />
          </button>
        </ScFilterDescription>
        {isOpen && children}
      </ScInternal>
      <ScDivider />
    </>
  );
};

const WrapperSkeleton = () => {
  return (
    <div>
      <ScFilterDescription>
        <Skeleton />
        <Skeleton />
      </ScFilterDescription>
      <Divider />
    </div>
  );
};

export { Wrapper, WrapperSkeleton };
