import { Form, Input } from 'antd';
import React from 'react';
import styled from 'styled-components';

const { TextArea } = Input;

const ScError = styled.div`
  color: #ff3333;
`;

const ScInput = styled(Input)``;

const ScTextArea = styled(TextArea)``;

export const FormInput = ({ form, field, ...props }: any) => {
  const isError = form.errors[field.name] != null;
  const errorMessage = isError ? <ScError>{form.errors[field.name]}</ScError> : null;
  const InputElement = props.type === 'textarea' ? ScTextArea : ScInput;

  return (
    <Form.Item
      hasFeedback={isError}
      help={errorMessage}
      validateStatus={isError ? 'error' : undefined}
    >
      <InputElement {...props} onChange={(value: any) => field.onChange(field.name)(value)} />
    </Form.Item>
  );
};
