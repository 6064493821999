import { Slider as AntdSlider } from 'antd';
import { debounce } from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import { Wrapper, WrapperProps } from './Wrapper';

export type SliderProps = {
  min: number;
  max: number;
  value?: [number, number];
  step?: number;
  onChange?: (value: [number, number]) => void;
} & WrapperProps;

const ScWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

function formatTooltipText(value: number): string {
  return value.toLocaleString(undefined, {
    useGrouping: value > 9999 ? true : false
  });
}

const Slider = ({
  min,
  max,
  onChange = (...args) => {},
  category,
  categoryId,
  value,
  startOpen = true,
  onCollapse,
  step = 1
}: SliderProps) => {
  const [internalValue, setInternalValue] = useState<[number, number]>(value || [min, max]);
  const [firstRender, setFirstRender] = useState(true);

  const debouncedChange = useCallback(debounce(onChange, 500, { leading: false, trailing: true }), [
    onChange
  ]);

  useEffect(() => {
    if (!firstRender && debouncedChange) {
      debouncedChange(internalValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [internalValue]);

  useEffect(() => {
    if (firstRender === true) {
      setFirstRender(false);
    }
  }, [firstRender]);

  return (
    <Wrapper
      category={category}
      categoryId={categoryId}
      onCollapse={onCollapse}
      startOpen={startOpen}
    >
      <ScWrapper>
        <AntdSlider
          onChange={newValues => {
            setInternalValue(newValues as [number, number]);
          }}
          min={min}
          max={max}
          value={internalValue}
          step={step}
          tipFormatter={formatTooltipText}
          range
        />
      </ScWrapper>
    </Wrapper>
  );
};
export { Slider };
