import { useEffect } from 'react';

const useExternalScripts = () => {

  // Implement cookies popup notification
  useEffect(() => {
    const script = document.createElement('script');

    script.src = "https://cdn-cookieyes.com/client_data/ea84963df6e384d1602ab3c0/script.js";
    script.async = true;
    script.id = "cookieyes";

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    }
  }, []);

};

export { useExternalScripts };
