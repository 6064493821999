import { useEffect } from 'react';
import ReactGA from 'react-ga';
import { useLocation } from 'react-router-dom';

const useGoogleAnalytics = trackingId => {
  const location = useLocation();

  useEffect(() => {
    if (process.env.NODE_ENV !== 'production') return;
    ReactGA.initialize(trackingId);
  }, [trackingId]);

  useEffect(() => {
    if (process.env.NODE_ENV !== 'production') return;
    ReactGA.pageview(`${location.pathname}${location.search}`);
  }, [location]);

  return undefined;
};

export { useGoogleAnalytics };
