import { Page } from '@hypercharge/bbs-website-commons/lib/types/bbs';
import { notification } from 'antd';
import { QuillDeltaToHtmlConverter } from 'quill-delta-to-html';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import SafelySetInnerHtml from 'safely-set-inner-html';
import styled from 'styled-components';
import { LayoutFixedWidthWrapper } from '../components/LayoutFixedWidthWrapper';
import { Loading } from '../components/Loading';
import { getPage } from '../context/PagesHook';
import { ALLOWED_HTML, BBS_TEXT_COLOR, BREAKPOINTS } from '../utils/constants';
import { PageDescriptionT, Status } from '../utils/types';
import { MetaTags } from '../components/MetaTags';

const filterInnerHtml = new SafelySetInnerHtml(ALLOWED_HTML);

const ScWidthLimiter = styled(LayoutFixedWidthWrapper)`
  margin: 0 auto 10% auto;
  @media screen and (max-width: ${BREAKPOINTS.sm}px) {
    padding: 0 10px 0 10px;
  }
`;

const ScTitle = styled.h2`
  font-weight: 600;
  color: ${BBS_TEXT_COLOR};
`;

const ScContent = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  background-color: #fff;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
  padding: 20px;
  * :not(a) {
    color: ${BBS_TEXT_COLOR};
  }
`;

const GenericPage = ({
  id: pageId,
  onPageDescription,
  breadcrumbs
}: PageDescriptionT & { id: string; breadcrumbs: React.ReactNode }) => {
  const { i18n } = useTranslation();

  const [page, setPage] = useState<Page>({
    id: '',
    title: '',
    content: []
  });

  const [pageContent, setPageContent] = useState<string>('');

  const [status, setStatus] = useState<Status>(Status.Loading);
  const { t } = useTranslation();

  useEffect(() => {
    if (pageId) {
      getPage(pageId, i18n.language)
        .then(page => {
          if (!page.content || !page.content.ops) {
            setPage(page);
            setStatus(Status.Success);
            return;
          }

          const converter = new QuillDeltaToHtmlConverter(page.content.ops, { encodeHtml: false });
          setPageContent(converter.convert());

          setPage(page);
          setStatus(Status.Success);
        })
        .catch(() => {
          setStatus(Status.Error);
        });
    }
  }, [i18n.language, onPageDescription, pageId]);

  useEffect(() => {
    if (page && onPageDescription) {
      onPageDescription(page.title);
    }
  }, [onPageDescription, page]);

  if (status === Status.Loading) {
    return <Loading />;
  }

  if (status === Status.Error) {
    const message = {
      message: t('ERROR_BOUNDARY__TITLE'),
      description: ''
    };
    notification.error({ ...message });
  }

  return (
    <ScWidthLimiter>
      <MetaTags title={page.title} />
      {breadcrumbs}
      <ScTitle>{page.title}</ScTitle>
      <ScContent>
        <span>{filterInnerHtml.transform(pageContent)}</span>
      </ScContent>
    </ScWidthLimiter>
  );
};

export { GenericPage };
