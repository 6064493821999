import {
  CarrouselImage,
  Vehicle,
  VehicleSummary
} from '@hypercharge/bbs-website-commons/lib/types/bbs';
import * as Sentry from '@sentry/browser';
import { useEffect, useState } from 'react';
import { getImageUrl, rewriteImagesUrl } from '../utils/files';
import { FetchValue, HttpErrors, hyperfetch } from '../utils/httpClient';
import { ImageResizerT } from '../utils/types.js';

export function generateVehicleUrl(id: string, slug: string): string {
  // - is for broken stuff in staging
  return `/vehicles/${id}/${slug || '-'}`;
}

export function enhanceVehicleSummaries(
  summaries: VehicleSummary[],
  sizeAttrs: ImageResizerT
): VehicleSummary[] {
  return summaries.map(summary => enhanceVehicleSummary(summary, sizeAttrs));
}

export function enhanceVehicleSummary(
  summary: VehicleSummary,
  sizeAttrs: ImageResizerT
): VehicleSummary {
  summary.url = generateVehicleUrl(summary.id, summary.slug);

  if (summary.imagesUrl) {
    summary.imagesUrl = rewriteImagesUrl(summary.imagesUrl, sizeAttrs);
  }

  return summary;
}

function handleError(
  setNotFound: React.Dispatch<React.SetStateAction<boolean>>,
  setOtherFailure: React.Dispatch<React.SetStateAction<boolean>>,
  error?: any,
  response?: FetchValue<Vehicle>
) {
  if (!error && response && !response.error) return;

  if (response && response.error === HttpErrors.NotFoundRequestError) {
    return setNotFound(true);
  }

  Sentry.captureException(error);

  setOtherFailure(true);
}

function generateCarrouselUrlsFromImageIds(imageIds: string[]): CarrouselImage[] {
  return imageIds.map<CarrouselImage>(imageId => {
    return {
      carrouselUrl: getImageUrl(imageId, { height: 375 }),
      fullImageUrl: getImageUrl(imageId)
    };
  });
}

const useVehicle = (vehicleId: string | null, languageCode: string) => {
  const [vehicle, setVehicle] = useState<Vehicle | null>(null);
  const [notFound, setNotFound] = useState<boolean>(false);
  const [otherFailure, setOtherFailure] = useState<boolean>(false);

  useEffect(() => {
    if (!vehicleId) {
      setVehicle(null);
      setNotFound(true);
      return;
    }

    const fetchResponse = hyperfetch<Vehicle>(`/api/get-vehicle/${vehicleId}/${languageCode}`);

    fetchResponse.promise
      .then(response => {
        if (response.error) return handleError(setNotFound, setOtherFailure, null, response);

        if (response.data) {
          fillVehicleVariables(response.data);
        }
      })
      .catch(e => {
        handleError(setNotFound, setOtherFailure, e);
      });

    function fillVehicleVariables(vehicle: Vehicle) {
      if (vehicle.summary.imagesUrl) {
        vehicle.carrouselImages = generateCarrouselUrlsFromImageIds(vehicle.summary.imagesUrl);
      }

      vehicle.imagesForPrinting = generateCarrouselUrlsFromImageIds(
        Array.isArray(vehicle.imagesForPrinting) ? vehicle.imagesForPrinting : []
      ).map(c => c.fullImageUrl);

      setVehicle(vehicle);
      setNotFound(false);
      setOtherFailure(false);
    }

    return () => {
      fetchResponse.abort();
    };
  }, [vehicleId, languageCode]);

  return { otherFailure, notFound, vehicle };
};

export { useVehicle };
