import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link, LinkProps } from 'react-router-dom';
import { getLocalizedPath } from '../context/i18n';

export default ({ to, children, ...props }: LinkProps<{}>) => {
  const {
    i18n: { language }
  } = useTranslation();
  return (
    <Link to={typeof to === 'string' ? getLocalizedPath(to as string, language) : to} {...props}>
      {children}
    </Link>
  );
};
