import { VehicleSummary } from '@hypercharge/bbs-website-commons/lib/types/bbs';
import { get } from 'lodash';
import { useEffect, useState } from 'react';
import { hyperfetch, json } from '../utils/httpClient';
import { enhanceVehicleSummaries } from './VehicleHook';

type LatestVehicleT = {
  limit: number;
  languageCode: string;
  singleImage: boolean;
  onlyHighlightedVehicles?: boolean;
};

async function getVehicleSummaries({
  limit,
  languageCode,
  singleImage,
  onlyHighlightedVehicles
}: LatestVehicleT) {
  const cancelableResponse = hyperfetch<VehicleSummary[]>('/api/get-latest-vehicles', {
    method: 'POST',
    body: json({ limit, languageCode, singleImage, onlyHighlightedVehicles })
  });

  const response = await cancelableResponse.promise;

  if (response.error) throw response.error;

  return get(response, 'data', []);
}

const useLatestVehicles = ({
  limit,
  languageCode,
  singleImage,
  onlyHighlightedVehicles = false
}: LatestVehicleT) => {
  const [vehicles, setVehicles] = useState<VehicleSummary[] | null>(null);

  useEffect(() => {
    getVehicleSummaries({ limit, languageCode, singleImage, onlyHighlightedVehicles })
      .then(summaries => enhanceVehicleSummaries(summaries, { height: 200 }))
      .then(summaries => setVehicles(summaries))
      .catch(e => console.error(e));
  }, [limit, languageCode, singleImage, onlyHighlightedVehicles]);

  return vehicles;
};

export { useLatestVehicles };
