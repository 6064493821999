import { ContactForm } from '@hypercharge/bbs-website-commons/lib/types/bbs';
import { hyperfetch, json, save } from '../utils/httpClient';

export async function sendContactForm(form: ContactForm, recaptchaResponse: string) {
  const fetchResponse = hyperfetch<boolean>('/api/send-contact-form', {
    body: json({
      contactForm: form,
      recaptchaResponse
    }),
    method: 'POST'
  });

  const requestState = await save(fetchResponse);

  return requestState;
}
