import { Banner as BannerT } from '@hypercharge/bbs-website-commons/lib/types/bbs';
import { LinkValue } from '@hypercharge/cms/lib/types';
import { Button } from 'antd';
import { isEmpty } from 'lodash';
import React from 'react';
import Skeleton from 'react-loading-skeleton';
import styled from 'styled-components';
import { BREAKPOINTS } from '../utils/constants';
import { getImageUrl } from '../utils/files';
import { Status } from '../utils/types';
import LocalizedLink from './LocalizedLink';

export type BannerProps = {
  banner: BannerT;
  requestStatus?: Status;
  className?: string;
};

export enum BannerType {
  header = 'header',
  story = 'story'
}

const DEFAULT_HEIGHT = 90;

const ScText = styled.span`
  color: white;
  text-align: center;
  margin: 0 0 0.7rem 0;
  text-shadow: 1px 1px rgba(0, 0, 0, 0.5);
  word-wrap: break-word;
`;

const ScTitle = styled(ScText)`
  font-size: 3rem;
  font-weight: 400;
`;

const ScSubtitle = styled(ScText)`
  font-size: 2rem;
  font-weight: 400;
`;

const ScCallToActionWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

const ScSpacer = styled.div`
  flex-grow: 1;
`;

const ScSkeletonWrapper = styled.div`
  margin-top: 10px;
`;

const ScBanner = styled.div<{ backgroundColor?: string; imageUrl: string; bannerHeight: number }>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: ${props => props.backgroundColor || '#DDD'};
  background-image: url(${props => props.imageUrl});
  background-size: cover;
  min-height: ${props => props.bannerHeight}px;
  color: white;
  padding: 4rem 8rem;
  @media screen and (max-width: ${BREAKPOINTS.md}px) {
    padding: 1em;
  }
  border-radius: 3px;
  overflow: hidden;
`;

const CallToAction = ({ text, link }: LinkValue) => {
  return (
    <ScCallToActionWrapper>
      <ScSpacer />
      <LocalizedLink to={link}>
        <Button type="primary" size="large">
          {text}
        </Button>
      </LocalizedLink>
      <ScSpacer />
    </ScCallToActionWrapper>
  );
};

const Banner = ({ banner, className }: BannerProps) => {
  const { title, description, image, backgroundColor, callToAction, height } = banner as BannerT;
  const bannerHeight = height || DEFAULT_HEIGHT;
  const imageUrl = isEmpty(image) ? '' : getImageUrl(image![0], { height: bannerHeight });

  return (
    <ScBanner
      backgroundColor={backgroundColor}
      imageUrl={imageUrl}
      bannerHeight={bannerHeight}
      className={className}
    >
      {title && <ScTitle>{title}</ScTitle>}
      {description && <ScSubtitle>{description}</ScSubtitle>}
      {callToAction && <CallToAction {...callToAction} />}
    </ScBanner>
  );
};

const BannerSkeleton = (): JSX.Element => {
  return (
    <ScSkeletonWrapper>
      <Skeleton height={DEFAULT_HEIGHT} />
    </ScSkeletonWrapper>
  );
};

export { Banner, BannerSkeleton };
