import { VehicleStatus } from '@hypercharge/bbs-website-commons/lib/types/bbs';
import { Tag as TagAntd } from 'antd';
import { TFunction } from 'i18next';
import styled from 'styled-components';

export const Tag = styled(TagAntd)`
  &&& {
    height: 35px;
    line-height: 35px;
    font-size: calc(16px + 0.3vw);
    border-radius: 2px;
  }
`;

export type TagAttributesForStatus = {
  text: string;
  color: string;
};

export const getTagAttributesForStatus = (
  t: TFunction,
  status: VehicleStatus
): TagAttributesForStatus => {
  switch (status) {
    case VehicleStatus.NEW:
      return {
        color: 'rgba(235, 199, 67, 0.85)',
        text: t('VEHICLE_CARD__TAG_NEW')
      };
    case VehicleStatus.SOLD:
      return {
        color: 'rgba(215, 80, 92, 0.95)',
        text: t('VEHICLE_CARD__TAG_SOLD')
      };
    case VehicleStatus.RESERVED_PREPAID:
      return {
        color: 'rgba(222, 140, 88, 0.85)',
        text: t('VEHICLE_CARD__TAG_RESERVED')
      };
    case VehicleStatus.RENTED:
      return {
        color: 'rgba(107, 152, 211, 0.85)',
        text: t('VEHICLE_CARD__TAG_RENTED')
      };
    default:
      return {
        color: '',
        text: ''
      };
  }
};
