import React from 'react';
import styled from 'styled-components';
import { BREAKPOINTS } from '../utils/constants';
import LocalizedLink from './LocalizedLink';

export type FooterNavigationT = {
  url: string;
  description: string;
};

const ScWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  text-transform: uppercase;
  line-height: 2em;
  a {
    color: white;
    margin-right: 2.5rem;
    @media screen and (max-width: ${BREAKPOINTS.sm}px) {
      margin-right: 1rem;
    }
  }
`;

const FooterNavigation = ({ navigationItems }: { navigationItems: FooterNavigationT[] }) => {
  return (
    <ScWrapper>
      {navigationItems.map((item, i) => (
        <LocalizedLink key={i} to={item.url}>
          {item.description}
        </LocalizedLink>
      ))}
    </ScWrapper>
  );
};

export { FooterNavigation };
