// https://stackoverflow.com/questions/48721290/ant-design-huge-imports?answertab=active#tab-top
// when using an antd icon via the <Icon tag it needs to be explicitly imported and exported here
// as the antd v3 doesn't correctly do treeshaking on the icons
export { default as CheckCircleFill } from '@ant-design/icons/lib/fill/CheckCircleFill';
export { default as CloseCircleFill } from '@ant-design/icons/lib/fill/CloseCircleFill';
export { default as ExclamationCircleFill } from '@ant-design/icons/lib/fill/ExclamationCircleFill';
export { default as FilterFill } from '@ant-design/icons/lib/fill/FilterFill';
export { default as InfoCircleFill } from '@ant-design/icons/lib/fill/InfoCircleFill';
export { default as CheckCircleOutline } from '@ant-design/icons/lib/outline/CheckCircleOutline';
export { default as CheckOutline } from '@ant-design/icons/lib/outline/CheckOutline';
export { default as CloseCircleOutline } from '@ant-design/icons/lib/outline/CloseCircleOutline';
export { default as CloseOutline } from '@ant-design/icons/lib/outline/CloseOutline';
export { default as CompassOutline } from '@ant-design/icons/lib/outline/CompassOutline';
export { default as DoubleLeftOutline } from '@ant-design/icons/lib/outline/DoubleLeftOutline';
export { default as DoubleRightOutline } from '@ant-design/icons/lib/outline/DoubleRightOutline';
export { default as DownOutline } from '@ant-design/icons/lib/outline/DownOutline';
export { default as FilterOutline } from '@ant-design/icons/lib/outline/FilterOutline';
export { default as LeftOutline } from '@ant-design/icons/lib/outline/LeftOutline';
export { default as LoadingOutline } from '@ant-design/icons/lib/outline/LoadingOutline';
export { default as MailOutline } from '@ant-design/icons/lib/outline/MailOutline';
export { default as MenuOutline } from '@ant-design/icons/lib/outline/MenuOutline';
export { default as PhoneOutline } from '@ant-design/icons/lib/outline/PhoneOutline';
export { default as PrinterOutline } from '@ant-design/icons/lib/outline/PrinterOutline';
export { default as RightOutline } from '@ant-design/icons/lib/outline/RightOutline';
export { default as SearchOutline } from '@ant-design/icons/lib/outline/SearchOutline';
export { default as UpOutline } from '@ant-design/icons/lib/outline/UpOutline';
