import { Alert, Button, Input } from 'antd';
import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

export type NewsletterSubscribeProps = {
  onSubscribe: (email: string) => Promise<boolean>;
  className?: string;
};

const ScWrapper = styled.div`
  display: flex;
  flex-direction: column;
  color: rgba(0, 0, 0, 0.65);

  > span {
    font-size: 1.5rem;
    font-weight: 300;
    margin-bottom: 1rem;
    text-align: center;
  }

  button {
    text-transform: uppercase;
  }

  input:focus,
  input:hover {
    border-color: currentColor;
  }
`;

const ScActions = styled.div`
  display: flex;
  flex-direction: row;
  align-items: baseline;
`;

const ScInputWrapper = styled.div`
  margin-right: 1rem;
  input {
    background-color: transparent;
    font-size: 1.3rem;
    height: 32px;
    border-radius: 0;
    border-top: 0;
    border-right: 0;
    border-left: 0;
    color: rgba(0, 0, 0, 0.65);
    padding-left: 0;
  }
`;

const NewsletterSubscribe = ({ onSubscribe, className }: NewsletterSubscribeProps) => {
  const { t } = useTranslation();

  const [email, setEmail] = useState('');
  const [validEmail, setValidEmail] = useState<boolean | null>(null);
  const [successfullySent, setSuccessfullySent] = useState<boolean>(false);

  const emailInput = useRef<Input>(null);

  function isEmailValid() {
    if (!emailInput || !emailInput.current) return false;

    return email.trim().length > 0 && emailInput.current.input.validity.valid;
  }

  async function validateAndSubscribe() {
    if (!isEmailValid()) {
      setValidEmail(false);
      return false;
    }

    setValidEmail(true);
    debugger;
    const success = await onSubscribe(email);
    if (success) {
      setEmail('');
      setSuccessfullySent(true);
    }
  }

  return (
    <ScWrapper className={className}>
      <span>{t('NEWSLETTER')}</span>
      {validEmail === false && (
        <Alert
          message={t('NEWSLETTER__INVALID_EMAIL')}
          type="error"
          closable
          onClose={() => setValidEmail(null)}
        />
      )}
      {successfullySent && (
        <Alert
          message={t('NEWSLETTER_SUBSCRIBE_SUCCESS')}
          type="success"
          closable
          onClose={() => setSuccessfullySent(false)}
        />
      )}
      <ScActions>
        <ScInputWrapper>
          <Input
            size="small"
            type="email"
            ref={emailInput}
            value={email}
            onChange={e => {
              setEmail(e.target.value);
              setSuccessfullySent(false);
            }}
          />
        </ScInputWrapper>
        <Button type="primary" onClick={validateAndSubscribe}>
          {t('NEWSLETTER__SUBSCRIBE')}
        </Button>
      </ScActions>
    </ScWrapper>
  );
};

export { NewsletterSubscribe };
