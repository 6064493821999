import { Layout } from 'antd';
import React, { ReactNode, Suspense, useEffect, useState } from 'react';
import Helmet from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { BrowserRouter, Redirect, Route, Switch, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { Breadcrumb } from './components/Breadcrumb';
import ErrorBoundary from './components/ErrorBoundary';
import { Loading } from './components/Loading';
import { ConfigurationProvider } from './context/ConfigurationProvider';
import { useGoogleAnalytics } from './context/GoogleAnalyticsHook';
import { getLocalizedPath, getLocalizedUrl, LANGUAGE_OPTIONS } from './context/i18n';
import { Footer } from './layouts/Footer';
import { Header } from './layouts/Header';
import { Contact } from './pages/Contact';
import { GenericPage } from './pages/GenericPage';
import { Home } from './pages/Home';
import { Page404 } from './pages/Page404';
import { SellBus } from './pages/SellBus';
import { Vehicle } from './pages/Vehicle';
import { Vehicles } from './pages/Vehicles';
import { getBusTypeFilterQueryString } from './utils/helpers';
import { useExternalScripts } from './context/ConnectExternalScriptsHook';

const { Content } = Layout;

const ScLayout = styled(Layout)`
    @media screen {
        min-height: 100vh;
    }
    @media print {
        display: block;
    }
`;

const AppProviders = ({ children }: { children: ReactNode }) => {
  return (
    <Suspense fallback={<Loading />}>
      <Helmet titleTemplate="Belgian Bus Sales - %s"></Helmet>

      <ConfigurationProvider>
        <ErrorBoundary>
          <BrowserRouter>{children}</BrowserRouter>
        </ErrorBoundary>
      </ConfigurationProvider>
    </Suspense>
  );
};

const AppContent = () => {
  const { pathname } = useLocation();
  const {
    t,
    i18n: { language }
  } = useTranslation();
  useExternalScripts();
  useGoogleAnalytics(process.env.REACT_APP_GA_TRACKING_ID);

  const [description, setDescription] = useState<string>('');

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <>
      <Helmet>
        {LANGUAGE_OPTIONS.map(lng => {
          const altLanguage = lng.value;
          return (
            <link
              key={altLanguage}
              rel="alternate"
              hrefLang={altLanguage}
              href={getLocalizedUrl(window.location.href, language, altLanguage)}
            />
          );
        })}
      </Helmet>
      <ScLayout>
        <Header />
        <Content>
          <Route path="/:lang([a-z]{2})">
            {({ match, location }) => {
              const params = match ? match.params : {};
              const { lang = language } = params;

              /**
               * If language is not in route path, redirect to localized page
               */
              const { pathname, search } = location;
              if (!pathname.includes(`/${lang}`)) {
                return <Redirect to={getLocalizedPath(`${pathname}${search}`, lang)} />;
              }

              return (
                <>
                  <Helmet htmlAttributes={{ lang }}>
                    <meta name="description" content={t('SITE_DESCRIPTION')} />
                  </Helmet>
                  <Switch>
                    <Route
                      path={`/:lang/vehicles/:id/:slug`}
                      children={({ match }) => {
                        return (
                          <Vehicle
                            breadcrumbs={
                              <Breadcrumb separator=">">
                                <Breadcrumb.Item href="/">{t('HOME')}</Breadcrumb.Item>
                                <Breadcrumb.Item href="/vehicles">{t('VEHICLES')}</Breadcrumb.Item>
                                <Breadcrumb.Item href={(match && match.url) || ''}>
                                  {description}
                                </Breadcrumb.Item>
                              </Breadcrumb>
                            }
                            id={match && match.params.id}
                            onPageDescription={setDescription}
                          />
                        );
                      }}
                    />
                    <Route
                      path={`/:lang/vehicles`}
                      children={({ location: { state } }) => {
                        return (
                          <Vehicles
                            showTypeDescription={state && state.showTypeDescription}
                            breadcrumbs={
                              <Breadcrumb separator=">">
                                <Breadcrumb.Item href="/">{t('HOME')}</Breadcrumb.Item>
                                <Breadcrumb.Item href="/vehicles">{t('VEHICLES')}</Breadcrumb.Item>
                              </Breadcrumb>
                            }
                          />
                        );
                      }}
                    />
                    <Route
                      path={`/:lang/category/:categoryId/:categorySlug?`}
                      render={routeProps => {
                        const busTypeId = routeProps.match.params.categoryId;
                        return busTypeId ? (
                          <Redirect
                            to={{
                              pathname: `/${language}/vehicles`,
                              search: `?${getBusTypeFilterQueryString(busTypeId)}`,
                              state: { showTypeDescription: true }
                            }}
                          />
                        ) : (
                          <Redirect to={`/${language}/vehicles`} />
                        );
                      }}
                    ></Route>
                    <Route path={`/:lang/contact`}>
                      <Contact
                        breadcrumbs={
                          <Breadcrumb separator=">">
                            <Breadcrumb.Item href="/">{t('HOME')}</Breadcrumb.Item>
                            <Breadcrumb.Item href="/contact">
                              {t('NAVIGATION__CONTACT')}
                            </Breadcrumb.Item>
                          </Breadcrumb>
                        }
                      />
                    </Route>
                    <Route path={`/:lang/sell_bus`}>
                      <SellBus
                        breadcrumbs={
                          <Breadcrumb separator=">">
                            <Breadcrumb.Item href="/">{t('HOME')}</Breadcrumb.Item>
                            <Breadcrumb.Item href="/sell_bus">
                              {t('NAVIGATION__BUYERS_INFO')}
                            </Breadcrumb.Item>
                          </Breadcrumb>
                        }
                      />
                    </Route>
                    <Route
                      path={`/:lang/pages/:id/:slug`}
                      children={({ match }) => {
                        return (
                          <GenericPage
                            id={(match && match.params.id) || ''}
                            breadcrumbs={
                              <Breadcrumb separator=">">
                                <Breadcrumb.Item href="/">{t('HOME')}</Breadcrumb.Item>
                                <Breadcrumb.Item href={(match && match.url) || ''}>
                                  {description}
                                </Breadcrumb.Item>
                              </Breadcrumb>
                            }
                            onPageDescription={setDescription}
                          />
                        );
                      }}
                    />
                    <Route exact path={`/:lang`}>
                      <Home />
                    </Route>
                    <Route component={Page404} />
                  </Switch>
                </>
              );
            }}
          </Route>
        </Content>
        <Footer />
      </ScLayout>
    </>
  );
};

const App: React.FC = () => {
  return (
    <AppProviders>
      <AppContent />
    </AppProviders>
  );
};

export default App;
