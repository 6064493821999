import { VehicleSummary } from '@hypercharge/bbs-website-commons/lib/types/bbs';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';
import styled from 'styled-components';
import { BREAKPOINTS } from '../utils/constants';
import LocalizedLink from './LocalizedLink';
import { VehicleCardColumn, VehicleCardColumnSkeleton } from './VehicleCard';

export type RelatedVehiclesProps = {
  vehicles: VehicleSummary[] | null;
  className?: string;
  title?: string;
};

const ScFlexContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const ScDescriptionAndAction = styled.div`
  display: flex;
  flex-grow: 1;
  justify-content: space-between;
  margin-bottom: 0.6rem;
  font-weight: 600;
`;

const ScVehiclesList = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: start;

  @media screen and (max-width: ${BREAKPOINTS.sm}px) {
    justify-content: center;
  }

  @media screen and (max-width: ${BREAKPOINTS.xs}px) {
    justify-content: center;
  }
`;

const ScVehicleCardColumn = styled(VehicleCardColumn)`
  margin-top: 10px;

  @media screen and (max-width: ${BREAKPOINTS.md}px) {
    margin-bottom: 1.5rem;
  }
`;

const ScTitle = styled.div`
  @media (max-width: ${BREAKPOINTS.sm}px) {
    width: 100%;
    text-align: center;
  }
`;

const ScShowAll = styled(ScTitle)`
  @media (max-width: ${BREAKPOINTS.sm}px) {
    margin-top: 15px;
  }
`;

const renderShowAllLink = (label: string) => {
  return (
    <ScShowAll>
      <LocalizedLink to="/vehicles">{label}</LocalizedLink>
    </ScShowAll>
  );
};

const RelatedVehicles = ({ vehicles, className, title }: RelatedVehiclesProps) => {
  const { t } = useTranslation();
  const isMobile = useMediaQuery({ maxWidth: BREAKPOINTS.sm });

  const isLatest = !!title;
  return (
    <ScFlexContainer className={className}>
      {vehicles ? (
        <>
          {isLatest && (
            <ScDescriptionAndAction>
              <ScTitle>{title}</ScTitle>
              {isMobile ? null : renderShowAllLink(t('LATEST_VEHICLES__SHOW_ALL'))}
            </ScDescriptionAndAction>
          )}
          <ScVehiclesList>
            {vehicles.map(vehicle => {
              return <ScVehicleCardColumn key={vehicle.id} {...vehicle} />;
            })}
          </ScVehiclesList>
          {isMobile && isLatest ? renderShowAllLink(t('LATEST_VEHICLES__SHOW_ALL')) : null}
        </>
      ) : (
        <ScVehiclesList>
          {[...new Array(4)].map((_, i) => (
            <VehicleCardColumnSkeleton key={'card' + i} />
          ))}
        </ScVehiclesList>
      )}
    </ScFlexContainer>
  );
};

export { RelatedVehicles };
