import { VehicleStatus } from '@hypercharge/bbs-website-commons/lib/types/bbs';
import { isEmpty } from 'lodash';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';
import styled from 'styled-components';
import { BREAKPOINTS } from '../../utils/constants';
import { getFormattedPrice } from '../../utils/helpers';

export type DescriptionBarProps = {
  price?: number;
  priceComment: string;
  description: string;
  subtitle: string;
  referenceNumber: string;
  showDescription?: boolean;
  status: VehicleStatus;
};

const ScDescriptionBar = styled.div`
  background-color: #0b588c;
  display: flex;
  flex-direction: row;
  color: white;
  font-size: 1rem;
  min-height: 45px;
  width: 100%;
  justify-content: center;

  @media screen and (max-width: ${BREAKPOINTS.md}px) {
    background-color: #073b5e;
  }
`;

const ScWidthLimiter = styled.div`
  display: flex;
  flex-direction: row;
  max-width: 1000px;
  flex-grow: 1;
  align-items: center;

  @media screen and (max-width: ${BREAKPOINTS.md - 1}px) {
    flex-direction: row-reverse;
  }
`;

const ScTextWrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: start;
  padding-left: 5px;
  margin-right: 5px;
  justify-content: center;

  .text {
    font-weight: bold;
  }

  @media (max-width: ${BREAKPOINTS.sm}px) {
    padding-left: 10px;
  }
`;

const ScTextAndLabel = styled.span`
  margin-right: 5px;
  @media screen and (max-width: ${BREAKPOINTS.md}px) {
    .label {
      display: none;
    }
  }
`;

const ScSubtext = styled.span`
  font-size: 0.8rem;
`;

const ScSpacer = styled.div`
  flex-grow: 1;
`;

const TextWithSubtextAndLabel = ({
  text,
  subtext,
  label,
  className,
  span = true,
  h1 = false
}: {
  text: string;
  subtext: string;
  label: string;
  className?: string;
  span?: boolean;
  h1?: boolean;
}) => {
  const isMedium = useMediaQuery({ minWidth: BREAKPOINTS.md });

  return (
    <ScTextWrapper className={className}>
      <ScTextAndLabel>
        <span className="label">{label + ' '}</span>
        {(h1 && <h1 className="text">{text}</h1>) || (span && <span className="text">{text}</span>)}
      </ScTextAndLabel>
      {isMedium && <ScSubtext>{subtext}</ScSubtext>}
    </ScTextWrapper>
  );
};

const ScPricingText = styled(TextWithSubtextAndLabel)`
  align-items: flex-end;

  @media screen and (min-width: ${BREAKPOINTS.md}px) {
    background-color: rgba(0, 0, 0, 0.33);
    align-items: flex-start;
  }

  @media (min-width: ${BREAKPOINTS.sm}px) {
    min-width: 250px;
  }
`;

const ScReferenceText = styled(TextWithSubtextAndLabel)`
  align-items: flex-end;
  .text {
    font-weight: bold;
    font-size: 1.1rem;
  }
  @media screen and (max-width: ${BREAKPOINTS.md}px) {
    align-items: flex-start;
  }

  @media (min-width: ${BREAKPOINTS.sm}px) {
    min-width: 150px;
  }
`;

const ScDescription = styled(TextWithSubtextAndLabel)`
  h1 {
    color: #fff;
    font-size: 1em;
    font-weight: 400;
    margin: auto;
  }
  @media screen and (max-width: ${BREAKPOINTS.sm}px) {
    max-width: 180px;
    height: 100%;
  }
  justify-content: ${props => (isEmpty(props.label) ? 'center' : 'start')};
`;

const DescriptionBar = ({
  price,
  priceComment,
  description,
  subtitle,
  referenceNumber,
  showDescription = true,
  status
}: DescriptionBarProps) => {
  const { t } = useTranslation();
  return (
    <ScDescriptionBar>
      <ScWidthLimiter itemProp="offers" itemScope itemType="http://schema.org/Offer">
        <ScPricingText
          label={t('VEHICLE__PRICE')}
          text={
            price
              ? getFormattedPrice(price)
              : status === VehicleStatus.SOLD
              ? t('VEHICLE_CARD__TAG_SOLD')
              : '---'
          }
          subtext={priceComment}
        />
        <meta itemProp="price" content={`${price || ''}`} />
        <meta itemProp="priceCurrency" content="EUR" />
        {showDescription && <ScDescription text={description} subtext={subtitle} label="" h1 />}
        <ScSpacer style={{ flexGrow: 2 }} />
        <ScReferenceText text={referenceNumber} subtext={t('VEHICLE_CARD__REF_NUMBER')} label="" />
      </ScWidthLimiter>
    </ScDescriptionBar>
  );
};

export { DescriptionBar };
