import styled from 'styled-components';
import { ErrorMessage, Field, useField, useFormikContext } from 'formik';
import { Checkbox } from 'antd';
import React, { FC } from 'react';
import { Link } from 'react-router-dom';

type FormikCheckboxProps = {
  name: string;
  label: string;
  link?: string;
  linkText?: string;
}

const StyledFormikCheckbox = styled.div`
  padding: 10px 5px 20px;
`;

const StyledCheckbox = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 0;

  input {
    margin-right: 0.5rem;
  }

  label {
    margin-bottom: 0;
  }
`;

const StyledErrorMessage = styled(ErrorMessage)`
  color: #f5222d;
`;

const FormikCheckbox: FC<FormikCheckboxProps> = ({ name, label, link, linkText }) => {
  const [field, meta, helpers] = useField(name);
  const { isSubmitting } = useFormikContext();

  return (
    <StyledFormikCheckbox>
      <Field name={name}>
        {() => (
          <StyledCheckbox>
            <Checkbox
              disabled={isSubmitting}
              checked={field.value}
              onChange={() => helpers.setValue(!field.value)}
            >
              <label>
                {label} {link && linkText && <Link to={link}>{linkText}</Link>}
              </label>
            </Checkbox>
          </StyledCheckbox>
        )}
      </Field>
      <StyledErrorMessage name={name} component="div" />
    </StyledFormikCheckbox>
  );
};

export default FormikCheckbox;
