import { ParseOptions } from 'query-string';

export const BBS_PRIMARY_COLOR = '#0B77C0';
export const BBS_TEXT_COLOR = '#5D5E5E';

export const BREAKPOINTS = {
  xs: 320,
  sm: 576,
  md: 768,
  lg: 1024
};

export const MEDIA_QUERIES_MIN: { [sizeKey: string]: any } = Object.keys(BREAKPOINTS).reduce(
  (acc: Object, label: string) => {
    acc[label] = `@media screen and (min-width: ${BREAKPOINTS[label]}px)`;
    return acc;
  },
  {}
);

export const MEDIA_QUERIES_MAX: { [sizeKey: string]: any } = Object.keys(BREAKPOINTS).reduce(
  (acc: Object, label: string) => {
    acc[label] = `@media screen and (max-width: ${BREAKPOINTS[label]}px)`;
    return acc;
  },
  {}
);

export const SET_LANGUAGE = 'setLanguage';

export const CATEGORY_OTHERS = 'other';

//Whitelist of html elements that can be EVALuated
export const ALLOWED_HTML = {
  ALLOWED_TAGS: ['h1', 'h2', 'h3', 'h4', 'ul', 'ol', 'li', 'p', 'strong', 'br', 'img', 'a', 'em'],
  ALLOWED_ATTRIBUTES: ['className', 'src', 'href', 'target']
};

export const QUERY_OPTIONS: ParseOptions = {
  arrayFormat: 'bracket'
};

export const PAGE_ID_SEPARATOR = '-';
