import {
  PROPERTY_ID_BUS_TYPE,
  PROPERTY_ID_BUS_TYPE_DESCRIPTION
} from '@hypercharge/bbs-website-commons/lib/constants';
import { BusType } from '@hypercharge/bbs-website-commons/lib/types/bbs';
import { BackTop, Button, Drawer, notification } from 'antd';
import { get } from 'lodash';
import { QuillDeltaToHtmlConverter } from 'quill-delta-to-html';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';
import Spinner from 'react-spinkit';
import SafelySetInnerHtml from 'safely-set-inner-html';
import styled from 'styled-components';
import {
  VehiclesFilter,
  VehiclesFilterSkeleton
} from '../components/vehiclesFilter/VehiclesFilter';
import {
  VehiclesResults,
  VehiclesResultsSkeleton
} from '../components/vehiclesResults/VehiclesResults';
import { WidthLimiter } from '../components/WidthLimiter';
import { useConfiguration } from '../context/ConfigurationProvider';
import { useSearchVehicles } from '../context/SearchVehiclesHook';
import {
  ALLOWED_HTML,
  BBS_PRIMARY_COLOR,
  BBS_TEXT_COLOR,
  BREAKPOINTS,
  MEDIA_QUERIES_MAX
} from '../utils/constants';
import { RequestState } from '../utils/types';
import { MetaTags } from '../components/MetaTags';

const filterInnerHtml = new SafelySetInnerHtml(ALLOWED_HTML);

const ScHeadingLimiter = styled(WidthLimiter)`
  margin: 0 auto;
`;

const ScWidthLimiter = styled(WidthLimiter)`
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  margin: 0 auto 10px auto;
  font-size: 1rem;

  @media screen and (max-width: ${BREAKPOINTS.sm}px) {
    padding: 0 10px 0 10px;
  }

  ${MEDIA_QUERIES_MAX.md} {
    flex-wrap: wrap;
  }
`;

const ScResultsWrapper = styled.div`
  flex-grow: 1;
  position: relative;
`;

const ScVehiclesResults = styled(VehiclesResults)`
  background-color: white;
  box-shadow: 2px 2px 4px 2px #e8e8e8;
  
  @media print {
    box-shadow: none;
  }
`;

const ScBreadcrumbWrapper = styled.div`
  width: 100%;
`;

const ScFloatingBox = styled.div`
  position: fixed;
  bottom: 10px;
  z-index: 1001;
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: center;
`;

const ScDrawer = styled(Drawer)`
  .ant-drawer-body {
    padding: 0;
  }
`;

const ScFilterWrapper = styled.div<{ hasRightMargin?: boolean }>`
  margin-right: ${props => (props.hasRightMargin ? '10px' : null)};
  position: relative;
`;

const ScLoadingWrapper = styled.div<{ isLoading: boolean }>`
  opacity: ${props => (props.isLoading ? '0.2' : '1')};
  pointer-events: ${props => (props.isLoading ? 'none' : null)};
  min-height: ${props => (props.isLoading ? 'calc(40px + 2rem)' : null)};
`;

const ScSpinner = styled(Spinner)`
  position: absolute;
  top: 4rem;
  left: 50%;
  transform: translateX(-50%);
  &&& {
    color: #162133;
  }
`;

const Filters = ({
  searchState,
  clearAllFilters,
  aggregatedVehicles,
  replaceFiltersForProperty,
  hasRightMargin = true,
  className = '',
  didResetAggregations = false
}) => {
  const isLoading = searchState === RequestState.SENDING;
  if (isLoading && aggregatedVehicles.length === 0) {
    return (
      <ScFilterWrapper hasRightMargin={hasRightMargin}>
        <VehiclesFilterSkeleton />
      </ScFilterWrapper>
    );
  } else {
    return (
      <ScFilterWrapper hasRightMargin={hasRightMargin} className={className}>
        <ScLoadingWrapper isLoading={isLoading}>
          <VehiclesFilter
            onClear={() => clearAllFilters()}
            filterAggregations={aggregatedVehicles}
            onChange={replaceFiltersForProperty}
            didResetAggregations={didResetAggregations}
          />
        </ScLoadingWrapper>
        {isLoading && <ScSpinner name="three-bounce" fadeIn="none" />}
      </ScFilterWrapper>
    );
  }
};

const ScFiltersForLargeScreen = styled(Filters)`
  font-size: 0.8em;
  box-shadow: 2px 2px 4px 2px #e8e8e8;
  background-color: white;
  @media print {
    display: none;
  }
`;

const ScFiltersForSmallScreen = styled(Filters)`
  font-size: 1.2em;
  margin-bottom: 38px;
  @media print {
    display: none;
  }
`;

const ScButtonShowFilters = styled(Button)`
  border-color: ${BBS_PRIMARY_COLOR};
  @media print {
    display: none;
  }
`;

const ScHideFilterContainer = styled.div`
  width: 100%;
  position: fixed;
  bottom: 0px;
  height: 36px;
  background-color: white;
`;

const ScButtonHideFilters = styled(ScButtonShowFilters)`
  width: 90%;
  margin-left: 5%;
  margin-bottom: 2px;
`;

const ScBackTop = styled(BackTop)`
  && {
    right: 40px;
    bottom: 30px;
  }
  @media print {
    display: none;
  }
`;

const ScTypeDescriptionContainer = styled.div`
  color: ${BBS_TEXT_COLOR};
  h1 {
    font-size: 1.5rem;
    margin: 0;
    color: ${BBS_TEXT_COLOR};
  }
`;

const BusTypeDescription = ({ busType }: { busType: BusType }) => {
  const descriptionQuill: any = useMemo(() => {
    return get(busType, `${PROPERTY_ID_BUS_TYPE_DESCRIPTION}.ops`, null);
  }, [busType]);

  if (!descriptionQuill) return null;

  const descriptionHTML = new QuillDeltaToHtmlConverter(descriptionQuill, {
    encodeHtml: false
  }).convert();

  return (
    <ScWidthLimiter>
      <ScTypeDescriptionContainer>
        <h1>{busType.title}</h1>
        <span>{filterInnerHtml.transform(descriptionHTML)}</span>
      </ScTypeDescriptionContainer>
    </ScWidthLimiter>
  );
};

type Props = {
  breadcrumbs: React.ReactNode;
  showTypeDescription?: boolean;
};

const Vehicles = ({ breadcrumbs, showTypeDescription }: Props) => {
  const isLarge = useMediaQuery({ minWidth: BREAKPOINTS.lg });
  const [isDrawerVisible, setIsDrawerVisible] = useState(false);
  const { t, i18n } = useTranslation();
  const { busTypes } = useConfiguration();
  const {
    aggregatedVehicles,
    clearAllFilters,
    filters,
    pagination,
    replaceFiltersForProperty,
    searchState,
    setOrderTo,
    setPageTo,
    summaries,
    didResetAggregations
  } = useSearchVehicles(i18n.language);

  const busType = useMemo(() => {
    const busTypesFilter: string[] = get(filters, `filters.${PROPERTY_ID_BUS_TYPE}`, []);
    if (busTypesFilter.length === 1) {
      const selectedBusType = busTypes.find(busType => busType.entityId === busTypesFilter[0]);
      return selectedBusType ? selectedBusType : null;
    }

    return null;
  }, [busTypes, filters]);

  useEffect(() => {
    if (searchState === RequestState.ERROR) {
      notification.error({
        message: t('ERROR_BOUNDARY__TITLE')
      });
    }
  }, [searchState, t]);

  return (
    <>
      <ScHeadingLimiter>
        <ScBackTop />
        <MetaTags title={`${t('VEHICLES')} ${busType ? busType.title : ''}`} />
        <ScBreadcrumbWrapper>{breadcrumbs}</ScBreadcrumbWrapper>
      </ScHeadingLimiter>
      {busType && showTypeDescription && <BusTypeDescription busType={busType} />}
      <ScWidthLimiter>
        {isLarge && !didResetAggregations && (
          <ScFiltersForLargeScreen
            {...{
              aggregatedVehicles,
              clearAllFilters,
              replaceFiltersForProperty,
              searchState,
              didResetAggregations
            }}
          />
        )}
        {!isLarge && !didResetAggregations && (
          <>
            <ScFloatingBox>
              {!isDrawerVisible && (
                <ScButtonShowFilters onClick={() => setIsDrawerVisible(!isDrawerVisible)}>
                  {isDrawerVisible ? t('BROWSE__HIDE_FILTERS') : t('BROWSE__SHOW_FILTERS')}
                </ScButtonShowFilters>
              )}
            </ScFloatingBox>
            <ScDrawer visible={isDrawerVisible} width="100vw" placement="left" closable={false}>
              <ScFiltersForSmallScreen
                {...{
                  aggregatedVehicles,
                  clearAllFilters,
                  replaceFiltersForProperty,
                  searchState,
                  hasRightMargin: false,
                  didResetAggregations
                }}
              />
              <ScHideFilterContainer>
                <ScButtonHideFilters onClick={() => setIsDrawerVisible(!isDrawerVisible)}>
                  {isDrawerVisible ? t('BROWSE__HIDE_FILTERS') : t('BROWSE__SHOW_FILTERS')}
                </ScButtonHideFilters>
              </ScHideFilterContainer>
            </ScDrawer>
          </>
        )}
        <ScResultsWrapper>
          {searchState === RequestState.SUCCESS || summaries.length > 0 ? (
            <>
              <ScLoadingWrapper isLoading={searchState === RequestState.SENDING}>
                <ScVehiclesResults
                  results={summaries}
                  pagination={pagination}
                  ordering={{
                    orderBy: filters.orderBy,
                    orderByType: filters.orderByType,
                    setOrderTo
                  }}
                  onChangePage={setPageTo}
                  clearAllFilters={clearAllFilters}
                />
              </ScLoadingWrapper>
              {searchState === RequestState.SENDING && (
                <ScSpinner name="three-bounce" fadeIn="none" />
              )}
            </>
          ) : (
            <VehiclesResultsSkeleton />
          )}
        </ScResultsWrapper>
      </ScWidthLimiter>
    </>
  );
};

export { Vehicles };
