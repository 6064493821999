import React from 'react';
import { useTranslation } from 'react-i18next';
import LocalizedLink from '../components/LocalizedLink';
import ErrorPage from '../layouts/ErrorPage';

const Page404 = () => {
  const { t } = useTranslation();
  return (
    <ErrorPage title={t('ERROR_PAGE__PAGE_NOT_FOUND_TITLE')} imageUrl="/images/notSupported.png">
      <>
        {t('ERROR_PAGE__PAGE_NOT_FOUND_TEXT')}
        <LocalizedLink to="/"> {t('HOME')}</LocalizedLink>.
      </>
    </ErrorPage>
  );
};
export { Page404 };
