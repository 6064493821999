import { CarrouselImage } from '@hypercharge/bbs-website-commons/lib/types/bbs';
import { Icon } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import { useMediaQuery } from 'react-responsive';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import styled from 'styled-components';
import fallback from '../../assets/images/fallback.png';
import { BREAKPOINTS } from '../../utils/constants';

export type CarrouselProps = {
  images: CarrouselImage[];
  alt?: string;
};

type ImageProps = {
  imageUrl: string;
  onClick: () => void;
};

type PagingProps = {
  className?: string;
  next?: () => void;
  previous?: () => void;
};

const ScWrapper = styled.div`
  display: block;
  width: 100%;
  overflow-x: hidden;

  @media print {
    display: none;
  }
`;

const ScArrows = styled.div`
  button {
    cursor: pointer;
  }
`;

const Image = ({ imageUrl, onClick }: ImageProps) => {
  const ScImage = styled.div`
    border-right: 1px solid white;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  `;

  return (
    <ScImage onClick={onClick}>
      <img src={imageUrl} alt="" />
    </ScImage>
  );
};

const Paging = ({ className, next, previous }: PagingProps) => {
  return (
    <ScArrows className={className}>
      <button onClick={previous}>
        <Icon type="left" />
      </button>
      <button onClick={next}>
        <Icon type="right" />
      </button>
    </ScArrows>
  );
};

const ScPaging = styled(Paging)`
  position: absolute;
  bottom: 0px;
  right: 10px;
  font-size: 50px;
  font-weight: 500;
  color: white;

  button {
    background: none;
    border: none;
  }

  svg {
    filter: drop-shadow(2px 4px 6px black);
  }
`;

const Carrousel = ({ images, alt }: CarrouselProps) => {
  const isLarge = useMediaQuery({ minWidth: BREAKPOINTS.lg });
  const isMedium = useMediaQuery({ minWidth: BREAKPOINTS.md });

  const [slider, setSlider] = useState<Slider | null>(null);
  const [showFullImage, setShowFullImage] = useState<boolean>(false);
  const [currentSlide, setCurrentSlide] = useState<number>(0);

  const [internalImages, setInternalImages] = useState<CarrouselProps['images']>([]);

  useEffect(() => {
    if (images.length > 0) {
      setInternalImages(images);
    } else {
      setInternalImages([
        {
          carrouselUrl: fallback,
          fullImageUrl: fallback
        }
      ]);
    }
  }, [images]);

  let slidesToShow = 1;
  if (isMedium && internalImages.length >= 2) {
    slidesToShow = 2;
  }
  if (isLarge && internalImages.length >= 3) {
    slidesToShow = 3;
  }

  const sliderRef = useCallback((_slider: Slider | null) => {
    if (_slider) {
      setSlider(_slider);
    }
  }, []);

  function navigateAndFullscreen(index: number) {
    if (!slider) return;

    setCurrentSlide(index);
    setShowFullImage(true);
  }

  function nextSlideIndex() {
    return (currentSlide + 1) % internalImages.length;
  }

  function previousSlideIndex() {
    return (currentSlide + internalImages.length - 1) % internalImages.length;
  }

  return (
    <>
      {showFullImage && slider && images.length > 0 && (
        <Lightbox
          mainSrc={internalImages[currentSlide].fullImageUrl}
          nextSrc={internalImages[nextSlideIndex()].fullImageUrl}
          prevSrc={internalImages[previousSlideIndex()].fullImageUrl}
          onCloseRequest={() => setShowFullImage(false)}
          onMoveNextRequest={() => {
            setCurrentSlide(nextSlideIndex());
          }}
          onMovePrevRequest={() => {
            setCurrentSlide(previousSlideIndex());
          }}
          imageTitle={alt}
        />
      )}
      <ScWrapper>
        <div style={{ position: 'relative' }}>
          <Slider
            afterChange={setCurrentSlide}
            arrows
            draggable={false}
            infinite
            ref={sliderRef}
            slidesToScroll={1}
            slidesToShow={slidesToShow}
            adaptiveHeight={true}
          >
            {internalImages.map((image, i) => (
              <Image
                imageUrl={image.carrouselUrl}
                key={i}
                onClick={() => navigateAndFullscreen(i)}
              />
            ))}
          </Slider>
          {slider && internalImages.length > slidesToShow && (
            <ScPaging next={slider.slickNext} previous={slider.slickPrev} />
          )}
        </div>
      </ScWrapper>
    </>
  );
};

export { Carrousel };
