import React from 'react';
import PhoneInput from 'react-phone-input-2';
import styled from 'styled-components';
import { BBS_PRIMARY_COLOR } from '../utils/constants';

type PhoneNumberInputProps = {
  country?: string;
  value: string;
  onChange?: (value) => void;
  onBlur?: () => void;
  valid: boolean;
  disabled?: boolean;
  className?: string;
};

const ScPhoneInput = styled.div`
  .react-tel-input {
    input {
      height: 32px;
      width: 100%;
      border: 1px solid #d9d9d9;
    }

    input:disabled {
      background-color: #f5f5f5;
    }

    input:hover:not(:disabled),
    input:focus {
      border-color: ${BBS_PRIMARY_COLOR};
      border-right-width: 1px;
    }

    input:focus {
      box-shadow: 0 0 0 2px rgba(11, 119, 192, 0.2);
    }
  }
  #phone-form-control.error {
    border-color: red;
  }
`;

const PhoneNumberInput = ({
  country = 'be',
  onChange,
  onBlur,
  valid = true,
  value = '',
  disabled = false,
  className = ''
}: PhoneNumberInputProps) => {
  return (
    <ScPhoneInput className={className}>
      <PhoneInput
        country={'be'}
        value={value}
        onChange={value => onChange && onChange(value)}
        onBlur={() => onBlur && onBlur()}
        inputClass={valid ? '' : 'error'}
        copyNumbersOnly={true}
        disabled={disabled}
      />
    </ScPhoneInput>
  );
};

export { PhoneNumberInput };
