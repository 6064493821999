import {
  SEARCH_VEHICLES_DEFAULT_ORDER,
  SEARCH_VEHICLES_DEFAULT_ORDER_TYPE,
  SEARCH_VEHICLES_DEFAULT_RESULTS
} from '@hypercharge/bbs-website-commons/lib/constants';
import {
  FilterAggregation,
  Pagination,
  SearchByFilterReturn,
  VehicleFilter,
  VehicleSummary
} from '@hypercharge/bbs-website-commons/lib/types/bbs';
import { SortOrder } from '@hypercharge/lambda-utils/lib/search/searchTypes';
import { isEmpty, orderBy } from 'lodash';
import { parseUrl } from 'query-string';
import { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { QUERY_OPTIONS } from '../utils/constants';
import { failOnNullOrError, hyperfetch, json } from '../utils/httpClient';
import { RequestState } from '../utils/types';
import { enhanceVehicleSummaries } from './VehicleHook';

const SEARCH_VEHICLES_TERM: string = 'term';
const SEARCH_VEHICLES_ORDER: string = 'orderBy';
const SEARCH_VEHICLES_ORDER_TYPE: string = 'orderByType';
const SEARCH_VEHICLES_PAGE: string = 'page';
const SEARCH_VEHICLES_RESULTS_PER_PAGE: string = 'resultsPerPage';

function getEmptyFilter(languageCode: string): VehicleFilter {
  return {
    orderBy: SEARCH_VEHICLES_DEFAULT_ORDER,
    orderByType: SEARCH_VEHICLES_DEFAULT_ORDER_TYPE,
    languageCode,
    page: 1,
    filters: {},
    resultsPerPage: SEARCH_VEHICLES_DEFAULT_RESULTS,
    term: ''
  };
}

function convertSearchParamsIntoFilter(searchParams: string, languageCode: string): VehicleFilter {
  const vehicleFilter: VehicleFilter = getEmptyFilter(languageCode);

  const urlSearchParams = { ...parseUrl(searchParams, QUERY_OPTIONS).query };

  for (const [key, value] of Object.entries(urlSearchParams)) {
    if (key === SEARCH_VEHICLES_TERM) {
      vehicleFilter.term = value as string;
      continue;
    }

    if (key === SEARCH_VEHICLES_ORDER) {
      vehicleFilter.orderBy = value as string;
      continue;
    }

    if (key === SEARCH_VEHICLES_ORDER_TYPE) {
      vehicleFilter.orderByType = value === SortOrder.asc ? SortOrder.asc : SortOrder.desc;
      continue;
    }

    if (key === SEARCH_VEHICLES_PAGE) {
      vehicleFilter.page = parseInt(value as string);
    }

    if (key === SEARCH_VEHICLES_RESULTS_PER_PAGE) {
      vehicleFilter.resultsPerPage = parseInt(value as string);
    }

    if (Array.isArray(value)) {
      for (const arrVal of value) {
        if (!Array.isArray(vehicleFilter.filters[key])) {
          vehicleFilter.filters[key] = [];
        }
        vehicleFilter.filters[key].push(arrVal);
      }
    }
  }

  return vehicleFilter;
}

function convertFiltersIntoURL(filters: VehicleFilter, location: any): string {
  const urlSearchParams = new URLSearchParams();

  if (filters.term.trim().length > 0) {
    urlSearchParams.set(SEARCH_VEHICLES_TERM, filters.term);
  }

  urlSearchParams.set(SEARCH_VEHICLES_ORDER, filters.orderBy);

  urlSearchParams.set(SEARCH_VEHICLES_ORDER_TYPE, filters.orderByType);

  urlSearchParams.set(SEARCH_VEHICLES_PAGE, filters.page.toString());

  urlSearchParams.set(SEARCH_VEHICLES_RESULTS_PER_PAGE, filters.resultsPerPage.toString());

  Object.entries(filters.filters).forEach(([propertyId, values]) => {
    if (!Array.isArray(values)) return;

    values.forEach(value => {
      urlSearchParams.append(`${propertyId}[]`, value.replace(',', ''));
    });
  });

  const pathname: string = location.pathname as string;

  return `${pathname}?${decodeURI(urlSearchParams.toString())}`;
}

function reorderAggregations(aggregations: FilterAggregation[]): FilterAggregation[] {
  aggregations = aggregations.map(aggregation => {
    return {
      ...aggregation,
      multipleChoice: aggregation.multipleChoice
        ? orderBy(aggregation.multipleChoice, ['description', 'count'])
        : undefined
    };
  });
  return orderBy(aggregations, ['order', 'categoryDescription']);
}

function filterEmptyTextAggregations(aggregations: FilterAggregation[]): FilterAggregation[] {
  return aggregations.map(aggregation => {
    if (aggregation.multipleChoice) {
      aggregation.multipleChoice = aggregation.multipleChoice.filter(
        item => !isEmpty(item.description)
      );
    }

    return aggregation;
  });
}

async function performSearchByFilter(
  filter: VehicleFilter,
  includeAggregations: boolean
): Promise<SearchByFilterReturn> {
  return await failOnNullOrError<SearchByFilterReturn>(
    hyperfetch('/api/search-vehicles', {
      method: 'POST',
      body: json({ ...filter, includeAggregations })
    })
  );
}

const useSearchVehicles = (languageCode: string) => {
  const location = useLocation();
  const history = useHistory();

  const [summaries, setSummaries] = useState<VehicleSummary[]>([]);
  const [aggregatedVehicles, setAggregatedVehicles] = useState<FilterAggregation[]>([]);
  const [searchState, setSearchState] = useState<RequestState>(RequestState.SENDING);
  const [pagination, setPagination] = useState<Pagination>({
    currentPage: 0,
    resultsCount: 0,
    resultsPerPage: 0,
    totalPages: 0
  });
  const [filters, setFilters] = useState<VehicleFilter>(getEmptyFilter(languageCode));
  const [shouldGetAggregations, setShouldGetAggregations] = useState<boolean>(true);
  const [didResetAggregations, setDidResetAggregations] = useState<boolean>(false);

  useEffect(() => {
    setSearchState(RequestState.SENDING);
    const filters = convertSearchParamsIntoFilter(location.search, languageCode);

    performSearchByFilter(filters, shouldGetAggregations)
      .then(({ summaries, aggregatedVehicles, pagination, didResetAggregations }) => {
        setSummaries(enhanceVehicleSummaries(summaries, { width: 320, height: 266 }));
        setPagination(pagination);
        if (shouldGetAggregations) {
          setAggregatedVehicles(
            filterEmptyTextAggregations(reorderAggregations(aggregatedVehicles))
          );
          setDidResetAggregations(didResetAggregations);
        }

        setSearchState(RequestState.SUCCESS);
        setFilters(filters);
      })
      .catch(e => {
        console.error(e);
        setSearchState(RequestState.ERROR);
      })
      .finally(() => {
        setShouldGetAggregations(true);
      });
    //TODO (celso) study an alternative to this breaking the thermometer instead of curing the fever.
    //eslint-disable-next-line
  }, [location.search, languageCode]);

  function replaceFiltersForProperty(definitionId: string, values: string[]) {
    let newFilter = { ...filters };
    if (!newFilter.filters) {
      newFilter.filters = {};
    }

    if (values.length === 0) {
      delete newFilter.filters[definitionId];
    } else {
      newFilter.filters[definitionId] = values;
    }
    newFilter.page = 1;

    updateFilters(newFilter);
  }

  function updateFilters(filters: VehicleFilter) {
    const url = convertFiltersIntoURL(filters, location);
    history.push(url);
  }

  function setOrderTo(orderBy: string, orderByType: SortOrder) {
    const newFilters: VehicleFilter = { ...filters, orderBy, orderByType };
    updateFilters(newFilters);
  }

  function setPageTo(destinationPageNumber: number, resultsPerPage: number) {
    setShouldGetAggregations(false);
    const newFilters: VehicleFilter = { ...filters, page: destinationPageNumber, resultsPerPage };
    updateFilters(newFilters);
  }

  function clearAllFilters() {
    const newFilters: VehicleFilter = getEmptyFilter(languageCode);
    updateFilters(newFilters);
  }

  return {
    aggregatedVehicles,
    clearAllFilters,
    filters,
    pagination,
    replaceFiltersForProperty,
    searchState,
    setOrderTo,
    setPageTo,
    summaries,
    updateFilters,
    didResetAggregations
  };
};

export { useSearchVehicles, convertSearchParamsIntoFilter };
