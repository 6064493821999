import { Select } from 'antd';
import React from 'react';
import countryList from 'react-select-country-list';
import styled from 'styled-components';

interface CountrySelectPropsInterface {
  disabled?: boolean;
  onBlur: () => void;
  onChange: any;
  placeholder: string;
  valid: boolean;
  value: string;
}

export default function CountrySelect({
                                        value,
                                        placeholder,
                                        valid,
                                        onChange,
                                        onBlur,
                                        disabled
                                      }: CountrySelectPropsInterface) {
  const options = countryList().getData();

  return (
    <ScSelect
      showSearch
      value={value || undefined}
      placeholder={placeholder}
      valid={valid}
      onChange={onChange}
      onBlur={onBlur}
      optionFilterProp="children"
      disabled={disabled}
      filterOption={(input, option) =>
        (option.props.children as string).toLowerCase().indexOf(input.toLowerCase()) >= 0
      }
    >
      {options.map(({ value, label }) => (
        <Select.Option key={value} value={value}>
          {label}
        </Select.Option>
      ))}
    </ScSelect>
  );
}

const ScSelect = styled(Select)`
  [role='combobox'] {
    border-color: ${({ valid }: { valid: boolean }) => (!valid ? 'red' : '')};
  }
`;
