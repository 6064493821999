import { PROPERTY_ID_BUS_TYPE } from '@hypercharge/bbs-website-commons/lib/constants';

function getFormattedPrice(price: number, locale?: string): string {
  var fallbackLocale = window.navigator.language || 'nl-BE';
  return price.toLocaleString(locale || fallbackLocale, {
    style: 'currency',
    currency: 'EUR',
    currencyDisplay: 'symbol',
    maximumFractionDigits: 2
  });
}

const getBusTypeFilterQueryString = (busTypeId: string) => {
  return `${PROPERTY_ID_BUS_TYPE}[]=${busTypeId}`;
};

export { getFormattedPrice, getBusTypeFilterQueryString };
