export var FeatureType;
(function (FeatureType) {
    FeatureType[FeatureType["AGE"] = 0] = "AGE";
    FeatureType[FeatureType["HP"] = 1] = "HP";
    FeatureType[FeatureType["KM"] = 2] = "KM";
    FeatureType[FeatureType["EURONORM"] = 3] = "EURONORM";
    FeatureType[FeatureType["RETARDER"] = 4] = "RETARDER";
    FeatureType[FeatureType["KW"] = 5] = "KW";
    FeatureType[FeatureType["TRANSMISSION"] = 6] = "TRANSMISSION";
})(FeatureType || (FeatureType = {}));
export var VehicleStatus;
(function (VehicleStatus) {
    VehicleStatus["AVAILABLE"] = "beschikbaar";
    VehicleStatus["DELIVERED"] = "geleverd";
    VehicleStatus["NEW"] = "NEW";
    VehicleStatus["SOLD"] = "verkocht";
    VehicleStatus["RENTED"] = "verhuurd";
    VehicleStatus["RESERVED"] = "gereserveerd";
    VehicleStatus["RESERVED_PREPAID"] = "gereserveerd-vooruitbetaling";
})(VehicleStatus || (VehicleStatus = {}));
