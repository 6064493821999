import { FilterAggregation } from '@hypercharge/bbs-website-commons/lib/types/bbs';
import React, { useState } from 'react';
import styled from 'styled-components';
import { BBS_PRIMARY_COLOR } from '../../utils/constants';
import { Wrapper, WrapperProps } from './Wrapper';

export type RadioImageProps = {
  items: FilterAggregation['imageChoice'];
  onSelect?: (id: string) => void;
} & WrapperProps;

const ScWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  button {
    background: none;
    border: 0;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-items: center;
    align-items: center;
    border-radius: 2px;
    margin-right: 2px;
    color: ${BBS_PRIMARY_COLOR};

    span {
      font-size: 0.6rem;
    }
  }

  button.selected {
    background-color: ${BBS_PRIMARY_COLOR};
    color: white;
  }

  img {
    max-height: 30px;
  }
`;

const RadioImage = ({
  items,
  onSelect,
  category,
  categoryId,
  startOpen = true,
  onCollapse
}: RadioImageProps) => {
  const [selectedOption, setSelectedOption] = useState<string | null>(null);

  function onSelectOption(key) {
    setSelectedOption(key);
    onSelect && onSelect(key);
  }

  return (
    <Wrapper
      category={category}
      categoryId={categoryId}
      onCollapse={onCollapse}
      startOpen={startOpen}
    >
      <ScWrapper>
        {items &&
          items.map(item => (
            <button
              key={item.id}
              onClick={() => onSelectOption(item.id)}
              className={selectedOption === item.id ? 'selected' : ''}
            >
              <img src={item.imageUrl} alt={item.description} />
              <span>{item.description}</span>
            </button>
          ))}
      </ScWrapper>
    </Wrapper>
  );
};

export { RadioImage };
