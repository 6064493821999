import { VehicleSummary } from '@hypercharge/bbs-website-commons/lib/types/bbs';
import { Carousel } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import Skeleton from 'react-loading-skeleton';
import styled from 'styled-components';
import fallbackImage from '../assets/images/fallback.png';
import { BBS_PRIMARY_COLOR, BREAKPOINTS, MEDIA_QUERIES_MAX } from '../utils/constants';
import { getFormattedPrice } from '../utils/helpers';
import LocalizedLink from './LocalizedLink';
import { getTagAttributesForStatus, Tag } from './Tag';

const CARD_DEFAULT_WIDTH = 220;
const CARD_IMAGE_DEFAULT_HEIGHT = 150;
const CARD_ROW_IMAGE_WIDTH = 280;
const CARD_ROW_IMAGE_HEIGHT = 162;
const CAROUSEL_LIMIT = 8;

const ScSkeletonWrapper = styled.div`
  width: ${CARD_DEFAULT_WIDTH}px;
  margin: 20px 3px 20px 0;
`;

const ScCardColumn = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid #e8e8e8;
  box-shadow: 2px 2px 4px 2px #e8e8e8;
  border-radius: 4px;
  min-width: 240px;
  width: 24%;
  margin-right: 0.5%;
  margin-left: 0.5%;

  @media screen and (max-width: ${BREAKPOINTS.lg}px) {
    width: 24%;
    margin-right: 0.5%;
    margin-left: 0.5%;
  }

  @media screen and (max-width: ${BREAKPOINTS.md}px) {
    width: 49%;
    margin-right: 0.5%;
    margin-left: 0.5%;
  }

  @media screen and (max-width: ${BREAKPOINTS.sm}px) {
    width: 80%;
    margin-right: 10%;
    margin-left: 10%;
  }

  @media screen and (max-width: ${BREAKPOINTS.xs}px) {
    width: 90%;
    margin-right: 5%;
    margin-left: 5%;
  }
`;

const ScCardRow = styled.div`
  display: flex;
  flex-direction: column;
`;

const ScDescription = styled.div`
  background-color: ${BBS_PRIMARY_COLOR};
  border-radius: 4px 4px 0 0;
  color: white;
  font-size: 0.8rem;
  font-weight: 500;
  line-height: 32px;
  overflow: hidden;
  padding-left: 8px;
  text-align: start;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const ScHeaderRow = styled.div`
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  justify-content: space-between;
  align-items: center;

  .description {
    font-size: 1.2rem;
    font-weight: 500;
    color: ${BBS_PRIMARY_COLOR};
    margin: 0 1rem 0 0;

    :hover {
      text-decoration: underline 2px;
    }
  }

  .price {
    font-size: 1rem;
    font-weight: 400;
    color: #5e6061;
    margin: 0;
    flex-shrink: 0;
    @media screen and (max-width: ${BREAKPOINTS.sm}px) {
      font-size: 1rem;
    }
  }

  ${MEDIA_QUERIES_MAX.md} {
    flex-wrap: wrap;
    > * {
      width: 100%;
      margin-right: 0;
    }
  }

  h6 {
    font-size: 0.7rem;
    font-weight: 300;
    color: #979797;
    margin: 0;
  }
`;

const ScImgBox = styled.div`
  height: ${CARD_IMAGE_DEFAULT_HEIGHT}px;
  display: flex;
  flex-direction: column;
  background-size: cover;
  background-color: #f0f0f0;
  background-position: center;
`;

const ScPriceInfo = styled.div`
  display: flex;
  background: rgb(128, 128, 128);
  background: linear-gradient(
    180deg,
    rgba(128, 128, 128, 1) 0%,
    rgba(128, 128, 128, 0.3) 70%,
    rgba(172, 172, 172, 0) 100%
  );
  color: white;
  padding: 0 0 5px 8px;
  flex-direction: row;
  align-items: center;
`;

const ScPriceComment = styled.span`
  font-size: 0.6rem;
`;

const ScImagesAndDetails = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  @media screen and (max-width: ${BREAKPOINTS.sm}px) {
    flex-wrap: wrap;
  }
  position: relative;
`;

const ScCarouselWrapper = styled.div`
  @media screen and (max-width: ${BREAKPOINTS.sm}px) {
    width: 90vw;
    height: 50vw;
  }
  width: ${CARD_ROW_IMAGE_WIDTH}px;
  height: ${CARD_ROW_IMAGE_HEIGHT}px;
  background-size: cover;
`;

const ScCarouselImage = styled(ScCarouselWrapper)<{ imageUrl: string }>`
  background-image: url(${props => props.imageUrl});
`;

const ScImageSeeMore = styled(ScCarouselWrapper)`
  line-height: ${CARD_ROW_IMAGE_HEIGHT}px;
  text-align: center;
  background-color: #e6e9ec;
`;

const ScCarousel = styled(Carousel)`
  @media screen and (max-width: ${BREAKPOINTS.sm}px) {
    width: 90vw;
  }
  width: ${CARD_ROW_IMAGE_WIDTH}px;
  margin-bottom: 5px;

  ul.slick-dots.slick-dots-bottom {
    bottom: 6px;
    background-color: ${BBS_PRIMARY_COLOR}2B;
  }

  && .slick-dots li {
    padding-top: 2px;
  }

  && .slick-dots li button {
    height: 4px;
    border: 1px solid rgba(0, 0, 0, 0.6);
  }

  .slick-dots li button::before {
    display: none;
  }
  && .slick-dots {
    height: 8px;
  }
`;

const ScTable = styled.div`
  display: flex;
  font-weight: 300;
  font-size: 0.7rem;
  flex-grow: 1;
  flex-direction: column;

  .row {
    display: flex;
    flex-direction: row;
    min-height: 27px;
    align-items: center;

    .col {
      color: #494444;
      padding-left: 8px;
    }

    .col:first-child {
      width: 50%;
    }

    .col:nth-child(2) {
      font-weight: 500;
    }
  }

  .row:nth-child(odd) {
    background-color: #f2f4f7;
  }
`;

const ScSpacer = styled.div`
  flex-grow: 1;
`;

const ScFloater = styled.div`
  position: absolute;
  left: 0;
  z-index: 2;
  padding: 5px;
`;

type TableDetailsProps = {
  details?: VehicleSummary['details'];
  className?: string;
};

const TableDetails = ({ details, className }: TableDetailsProps) => {
  return (
    <ScTable className={className}>
      {details &&
        details.map((detail, i) => {
          let value = detail.value;
          try {
            if (detail.meta && detail.meta.type === 'number' && detail.meta.unit !== '') {
              const _value = parseFloat(detail.value);
              if (!Number.isNaN(_value)) {
                value = _value.toLocaleString(undefined, {
                  useGrouping: true
                });
                value = `${value} ${detail.meta.unit}`;
              }
            }
          } finally {
          }
          return (
            <div className="row" key={i}>
              <div className="col">{detail.key}</div>
              <div className="col">{value}</div>
            </div>
          );
        })}
    </ScTable>
  );
};

const ScTableDetailsForCardRow = styled(TableDetails)`
  @media screen and (max-width: ${BREAKPOINTS.sm}px) {
    min-width: 50vw;
  }
  min-width: ${CARD_ROW_IMAGE_WIDTH};
`;

const VehicleCardColumn = ({
  description,
  imagesUrl,
  price,
  priceComment,
  details,
  url,
  status,
  className = ''
}: VehicleSummary & { className?: string }) => {
  const { t } = useTranslation();
  const tagAttributes = getTagAttributesForStatus(t, status);
  const bgImg = imagesUrl && imagesUrl.length > 0 ? `url(${imagesUrl[0]})` : `url(${fallbackImage})`;

  return (
    <ScCardColumn className={className}>
      <LocalizedLink to={url || ''}>
        <ScDescription>{description}</ScDescription>
        <ScImgBox
          style={{
            backgroundImage: bgImg,
          }}
        >
          <ScPriceInfo>
            <span>{price ? `${getFormattedPrice(price)}` : ''}</span>
            &nbsp;
            {priceComment && <ScPriceComment>{priceComment}</ScPriceComment>}
            <ScSpacer />
            {tagAttributes.text.length > 0 && (
              <Tag color={tagAttributes.color}>{tagAttributes.text}</Tag>
            )}
          </ScPriceInfo>
        </ScImgBox>
      </LocalizedLink>
      {details && <TableDetails details={details} />}
    </ScCardColumn>
  );
};

const VehicleCardRow = ({
  description,
  price,
  referenceNumber,
  imagesUrl,
  details,
  url,
  className,
  status
}: VehicleSummary & { className?: string }) => {
  const { t } = useTranslation();
  const tagAttributes = getTagAttributesForStatus(t, status);
  return (
    <ScCardRow className={className}>
      <LocalizedLink to={url || ''}>
        <ScHeaderRow>
          <div className="description">{description}</div>
          <div className="price">{price ? `${getFormattedPrice(price)}` : ''}</div>
        </ScHeaderRow>
        {referenceNumber && (
          <ScHeaderRow>
            <h6>
              {t('VEHICLE_CARD__REF_NUMBER')}: {referenceNumber}
            </h6>
          </ScHeaderRow>
        )}
      </LocalizedLink>
      <ScImagesAndDetails>
        <ScFloater>
          {tagAttributes.text.length > 0 && (
            <Tag color={tagAttributes.color}>{tagAttributes.text}</Tag>
          )}
        </ScFloater>
        <ScCarousel>
          {imagesUrl && imagesUrl.length > 0 ? (
            imagesUrl.slice(0, CAROUSEL_LIMIT).map((image, i) => (
              <LocalizedLink to={url || ''} key={i}>
                <ScCarouselImage imageUrl={image} />
              </LocalizedLink>
            ))
          ) : (
            <LocalizedLink to={url || ''}>
              <ScCarouselImage imageUrl={fallbackImage} />
            </LocalizedLink>
          )}
          {imagesUrl && imagesUrl.length > CAROUSEL_LIMIT && (
            <LocalizedLink to={url || ''}>
              <ScImageSeeMore>
                <span>{t('VEHICLES_IMAGE_SEE_MORE')}</span>
              </ScImageSeeMore>
            </LocalizedLink>
          )}
        </ScCarousel>
        <ScTableDetailsForCardRow details={details} />
      </ScImagesAndDetails>
    </ScCardRow>
  );
};

const VehicleCardColumnSkeleton = (): JSX.Element => {
  return (
    <ScSkeletonWrapper>
      <Skeleton width={CARD_DEFAULT_WIDTH} height={CARD_IMAGE_DEFAULT_HEIGHT} />
      <Skeleton width={CARD_DEFAULT_WIDTH} />
      <Skeleton width={CARD_DEFAULT_WIDTH} />
      <Skeleton width={CARD_DEFAULT_WIDTH} />
      <Skeleton width={CARD_DEFAULT_WIDTH} />
    </ScSkeletonWrapper>
  );
};

const VehicleCardRowSkeleton = (): JSX.Element => {
  return (
    <ScCardRow>
      <Skeleton />
      <ScImagesAndDetails>
        <Skeleton width={CARD_ROW_IMAGE_WIDTH} height={CARD_IMAGE_DEFAULT_HEIGHT} />
        <div style={{ marginLeft: '10px', flexGrow: 1 }}>
          <Skeleton />
          <Skeleton />
          <Skeleton />
          <Skeleton />
        </div>
      </ScImagesAndDetails>
    </ScCardRow>
  );
};

export { VehicleCardColumn, VehicleCardRow, VehicleCardColumnSkeleton, VehicleCardRowSkeleton };
