import { hyperfetch, json, save } from '../utils/httpClient';

export async function subscribeToNewsletter(email: string) {
  const fetchResponse = hyperfetch<boolean>('/api/newsletter-subscribe', {
    body: json({ email }),
    method: 'POST'
  });

  const requestState = await save(fetchResponse);

  return requestState;
}
