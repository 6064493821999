import { TENANT_ID } from '@hypercharge/bbs-website-commons/lib/constants';
import { ImageResizerT } from './types';

const getImageUrl = (imageId: string, sizeAttrs: ImageResizerT = {}): string => {
  const request = {
    key: `${TENANT_ID}/${imageId}`,
    edits: {
      resize: {
        ...sizeAttrs,
        fit: 'inside',
        withoutEnlargement: true
      }
    }
  };
  return `${process.env.REACT_APP_IMAGES_SERVICE}/${btoa(JSON.stringify(request))}`;
};

const rewriteImagesUrl = (imagesUrl: string[], sizeAttrs: ImageResizerT): string[] => {
  return imagesUrl.map(imageUrl => {
    return getImageUrl(imageUrl, sizeAttrs);
  });
};

export { getImageUrl, rewriteImagesUrl };
