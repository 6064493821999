import { FALLBACK_LANGUAGE } from '@hypercharge/bbs-website-commons/lib/constants';
import { OptionT } from '@hypercharge/bbs-website-commons/lib/types/common';
import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-xhr-backend';
import { map, sortBy } from 'lodash';
import { initReactI18next } from 'react-i18next';

export const LANGUAGE_OPTIONS: OptionT[] = sortBy(
  [
    { value: 'nl', label: 'Nederlands' },
    { value: 'fr', label: 'Français' },
    { value: 'en', label: 'English' },
    { value: 'pl', label: 'Polski' },
    { value: 'de', label: 'Deutsch' },
    { value: 'ru', label: 'Pусский' }
  ],
  ['label']
);

export const getLocalizedUrl = (currentUrl: string, currLanguage: string, newLanguage: string) => {
  const currentLangPath = `/${currLanguage}`;
  if (currentUrl.indexOf(currentLangPath) !== -1) {
    return currentUrl.replace(currentLangPath, `/${newLanguage}`);
  } else {
    return currentUrl;
  }
};

export const getLocalizedPath = (path: string | undefined, language: string) => {
  if (path && !path.startsWith(`/${language}`)) {
    return `/${language}${path && path.indexOf('/') === 0 ? '' : '/'}${path || ''}`;
  }

  return `${path && path.indexOf('/') === 0 ? '' : '/'}${path || ''}`;
};

export const changeLanguage = async (history, language: string) => {
  if (language !== i18n.language) {
    const { pathname, search } = history.location;
    const newUrl = getLocalizedUrl(`${pathname}${search}`, i18n.language, language);
    await i18n.changeLanguage(language);
    history.push(newUrl);
  }
};

const options = {
  detection: {
    order: ['path', 'localStorage', 'navigator']
  },
  interpolation: {
    escapeValue: false // not needed for react
  },
  fallbackLng: FALLBACK_LANGUAGE,
  whitelist: map(LANGUAGE_OPTIONS, 'value'),
  react: {
    useSuspense: true
  }
};

i18n
  .use(Backend) // load translations from /public/locales
  .use(LanguageDetector)
  .use(initReactI18next)
  .init(options);

export default i18n;
