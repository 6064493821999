import { BusType } from '@hypercharge/bbs-website-commons/lib/types/bbs';
import { sortBy } from 'lodash';
import React, {
  createContext,
  PropsWithChildren,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react';
import { useTranslation } from 'react-i18next';
import { failOnNullOrError, hyperfetch } from '../utils/httpClient';
import { Status } from '../utils/types';

export type CompanyInformation = {
  name: string;
  address1: string;
  address2: string;
  phoneNumber: string;
  faxNumber: string;
  email: string;
  latitude: string;
  longitude: string;
};

type ContextValue = {
  companyInformation: CompanyInformation;
  busTypes: BusType[];
  status: Status;
  appStyle: {
    showMarginAfterMenu: boolean;
    showMarginBeforeFooter: boolean;
  };
  setAppStyle: (appStyle: ContextValue['appStyle']) => void;
};

//TODO: Fetch bbs common Info from CMS
const companyInformation: CompanyInformation = {
  name: 'Belgian Bus Sales',
  address1: 'Kruineikestraat 109',
  address2: 'B-3150 Tildonk',
  phoneNumber: '+32 (0)16 60 90 66',
  faxNumber: '+32 (0)16 60 90 67',
  email: 'info@belgianbussales.com',
  latitude: '50.954167',
  longitude: '4.6403295'
};

const fetchBusTypes = (languageCode: string): Promise<BusType[]> =>
  failOnNullOrError(
    hyperfetch<BusType[]>(`/api/get-bus-types/${languageCode}`, {
      method: 'GET'
    })
  );

export const ConfigurationContext = createContext<ContextValue | undefined>(undefined);

export const ConfigurationProvider = (props: PropsWithChildren<any>) => {
  const [busTypes, setBusTypes] = useState<BusType[]>();
  const [status, setStatus] = useState(Status.Idle);
  const { i18n } = useTranslation();
  const [appStyle, setAppStyle] = useState<ContextValue['appStyle']>({
    showMarginAfterMenu: true,
    showMarginBeforeFooter: true
  });

  useEffect(() => {
    setStatus(Status.Loading);
    fetchBusTypes(i18n.language)
      .then((result: BusType[]) => {
        setBusTypes(sortBy(result, type => type.title.trim()));
        setStatus(Status.Success);
      })
      .catch(err => {
        console.log(err);
        setStatus(Status.Error);
      });
  }, [i18n.language]);

  const contextValue: ContextValue = useMemo(
    () => ({
      companyInformation,
      busTypes: busTypes || [],
      status,
      appStyle,
      setAppStyle
    }),
    [busTypes, status, appStyle]
  );

  return <ConfigurationContext.Provider value={contextValue} {...props} />;
};

export const useConfiguration = (): ContextValue => {
  const value = useContext(ConfigurationContext);
  if (value === undefined) {
    throw new Error('useConfiguration must be used inside a ConfigurationProvider');
  }
  return value;
};
